<details-view [title]="'group.details.title' | translate"
              [objectName]="'group.details.objectName' | translate"
              [fixedBlockHidden]="false"
              [deleteRightOrFunc]="CompanyElementaryRight.COMPANY_GROUP_REMOVE"
              [createRightOrFunc]="CompanyElementaryRight.COMPANY_GROUP_CREATE"
              [deleteRightOrFunc]="CompanyElementaryRight.COMPANY_GROUP_CREATE"
              [parentComponent]="self"
              [object]="groupVersion">
  <div class="details-content">
    <form #form="ngForm">
      <fieldset *ngIf="groupVersion" [disabled]="groupVersion.id">
        <div class="bon-card-main-group">
          <div class="bon-card-group" *ngIf="!editable">
            <div class="bon-card-inner">
              <company-info [company]="groupVersion.groupHead"
                            [fullDetails]="true"
                            [showLink]="true"></company-info>
            </div>
            <div class="bon-card-inner" style="width: 70%">
              <company-ratings
                *ngIf="groupVersion.groupHead && groupVersion.groupHead.id"
                [company]="groupVersion.groupHead"
                [showGroupLiability]="true"></company-ratings>
            </div>
          </div>
          <div class="bon-header">{{'group.details.details' | translate}}</div>
          <div class="bon-card-group">
            <div class="bon-card-inner" *ngIf="groupVersion.companyGroup">
              <input-row labelKey="group.details.name" [(ngModel)]="groupVersion.name" [showErrors]="showErrors"
                         name="name" required></input-row>
              <form-row labelKey="group.details.groupHead" [showErrors]="showErrors">
                <item-combo *ngIf="!groupVersion.id || (groupVersion.groupCompanies && groupVersion.groupCompanies.length > 0)" class="bon-input-size" [items]="groupVersion.groupCompanies" [(ngModel)]="groupVersion.groupHead" name="groupHead">
                  <ng-template let-item="item">{{item.registrationName}}</ng-template>
                </item-combo>
              </form-row>
            </div>
            <div class="bon-card-inner">
              <textarea-row labelKey="group.details.comment" [(ngModel)]="groupVersion.comment"
                            [ngModelOptions]="{standalone: true}"></textarea-row>
            </div>
            <div class="bon-card-inner">
              <text-row labelKey="group.details.versionId"
                        [value]="groupVersion.id?.toString()"></text-row>
              <text-row labelKey="group.details.membersCount"
                        [value]="groupVersion.groupCompanies?.length.toString()"></text-row>
              <form-row labelKey="group.details.bondExposureSum">
                {{bondExposureSum | number}}
              </form-row>
              <form-row labelKey="group.details.insuranceExposureSum">
                {{insuranceExposureSum | number}}
              </form-row>
            </div>
          </div>
          <div class="bon-card-group">
            <company-selector-simple (selectItem)="onSelectMember($event)" labelKey="group.details.searchForMember"
                                     [open]="openSelectorEmitters['Member']" [showDuplicates]="false" [showGroup]="true"
                                     [searchModeSwitchable]="true"
                                     [textSearch]="true"></company-selector-simple>
          </div>
        </div>
        <div class="bon-card-group" *ngIf="groupVersion.groupCompanies && !deleted">
          <group-member-list [group]="groupVersion" [deleteButton]="canGroupRemove()" [groupEdition]="true"
                             [addMemberEventEmitter]="openSelectorEmitters['Member']"></group-member-list>
        </div>
      </fieldset>
    </form>
  </div>
  <div class="versions-content">
    <a-table [items]="group.companyGroupVersions" (selectItem)="onSelectVersion($event)" [canUnselect]="false">
      <column property="versionNumber" initialSort="down" labelKey="group.details.version" type="integer"
              class="small-column"></column>
      <column property="validFrom" labelKey="group.details.validFrom" type="date"></column>
      <column property="validTo" labelKey="group.details.validTo" type="date"></column>
    </a-table>
  </div>
  <div class="information-header" *ngIf="groupVersion">
    <b class='font-larger a-link' [hidden]="!previewLink()" (click)="routerService.toCompanyGroupPreview(group.id)"
       style='display: inline-block; width: 250px;'>{{groupVersion.name}}</b>
    <b style='display: inline-block; width: 120px;'>{{"group.details.id" | translate}}: {{groupVersion.companyGroup.id}}</b>
  </div>
</details-view>

<confirm-dialog #deletionConfirm [showCancelButton]="false"/>
