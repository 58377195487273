import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  AppConfigService,
  CompanyIdDto,
  GrowlService,
  LoggedUserService,
  MasterPolicyContractRevisionsDto,
  MasterPolicyContractService,
  PolicyContractBaseDto,
  PolicyContractService,
  PolicyContractTechnicalDetailsAttendantsDto,
  PolicyContractTechnicalDetailsDto,
  RouterService,
  StringUtils,
  UserIdDto,
  UserService,
} from '../../bonding_shared';
import {ActivatedRoute, Params} from '@angular/router';
import {PolicyContractViewSection, PolicyContractViewService} from './services/policy-contract-view.service';
import {
  CompanyType,
  ElementaryRight,
  PolicyElementaryRight,
  SalesRepElementaryRight,
} from '../../bonding_shared/model/dictionary-ids';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'policy-contract-technical-details',
  templateUrl: 'policy-contract-technical-details.component.html',
})
export class PolicyContractTechnicalDetailsComponent extends DetailsView implements OnInit {
  @ViewChild('ngForm', {static: true}) public ngForm: NgForm;
  readonly PolicyElementaryRight = PolicyElementaryRight;
  readonly SalesRepElementaryRight = SalesRepElementaryRight;
  readonly CompanyType = CompanyType;
  readonly PolicyContractViewSection = PolicyContractViewSection;

  public technicalDetails: PolicyContractTechnicalDetailsDto;
  public policyContracts: PolicyContractBaseDto[] = [];
  companies: CompanyIdDto[] = [];
  protected caption = 'Turnover declaration';
  public underwriters: UserIdDto[];
  public managers: UserIdDto[];
  revisions: MasterPolicyContractRevisionsDto[];

  constructor(
    private policyContractService: PolicyContractService,
    private masterPolicyContractService: MasterPolicyContractService,
    private userService: UserService,
    private route: ActivatedRoute,
    public routerService: RouterService,
    protected growlService: GrowlService,
    public appService: AppConfigService,
    private loggedUserService: LoggedUserService,
    private cd: ChangeDetectorRef,
    private viewService: PolicyContractViewService
  ) {
    super(growlService);
    this.saveButton.hidden = false;
    this.cancelButton.hidden = false;
    this.selectorNameList = ['ReinsuranceECA'];
    this.initializeSelectorEmitters(true);
  }

  ngOnInit() {
    this.form = this.ngForm.form;
    this.route.params.subscribe((params) => this.initializeView(params));
    this.loadUsers();
  }

  initializeView(params: Params) {
    this.cd.detach();
    const id = +params['id'];

    this.policyContractService.getTechnicalDetails(id).subscribe({
      next: (data) => {
        this.technicalDetails = data;
        if (!this.technicalDetails.attendants) {
          this.technicalDetails.attendants = <PolicyContractTechnicalDetailsAttendantsDto>{};
        }
        this.initializePolicyContracts(data.policyContract.masterPolicyContract.id);
        this.getMasterPolicyContractRevisionsDto(data.policyContract.masterPolicyContract.id);
      },
      complete: () => {
        this.cd.reattach();
        this.cd.detectChanges();
      },
    });
  }

  loadUsers() {
    this.userService.getPolicySupport().subscribe((res) => {
      this.underwriters = res;
      this.managers = res;
    });
  }

  initializePolicyContracts(masterPolicyContractId: number) {
    this.masterPolicyContractService
      .getMasterPolicyContract(masterPolicyContractId)
      .subscribe((mpc) => (this.policyContracts = mpc.policyContracts));
  }

  getMasterPolicyContractRevisionsDto(masterPolicyContractId: number) {
    this.masterPolicyContractService.getMasterPolicyContractRevisionsDto(masterPolicyContractId).subscribe((r) => {
      this.revisions = r;
    });
  }

  onSave() {
    this.inProgress = true;
    this.showErrors = false;
    if (!this.form.valid) {
      this.showErrors = true;
      this.inProgress = false;
      this.showFormError();
      StringUtils.logFormInvalidFields(this.form);
      return;
    }
    this.policyContractService.updateTechnicalDetails(this.technicalDetails).subscribe({
      next: (data) => {
        this.technicalDetails = data;
        this.routerService.toPolicyContractDetailsByPolicyContractId(this.technicalDetails.policyContract.id);
      },
      error: (error) => {
        this.handleServerError(error);
      },
    });
  }

  onCancel() {
    super.onCancel(this.route);
  }

  onChangePolicyYear(policyYear: number) {
    const newPolicyYear = this.policyContracts.find((policyContract) => policyContract.policyYear === +policyYear);
    if (newPolicyYear) {
      this.routerService.toPolicyContractTechnicalDetails(newPolicyYear.id);
    }
  }

  showCession(): boolean {
    return this.visible(PolicyContractViewSection.TECHNICAL_DETAILS__CESSION, true);
  }

  showMonitoring(): boolean {
    return this.visible(PolicyContractViewSection.POLICY_CONTRACT_DATA__MONITORING);
  }

  private visible(visibilityEnum: PolicyContractViewSection, defaultVisibility = false): boolean {
    return (
      this.technicalDetails &&
      this.viewService.isSectionVisible(this.technicalDetails.policyContract.type.id, visibilityEnum, defaultVisibility)
    );
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }

  public addRelationButtonVisible(): boolean {
    return (
      this.technicalDetails &&
      this.loggedUserService.hasRight(ElementaryRight.BROKERCONTRACTRELATION_CREATE_POLICY) &&
      this.loggedUserService.hasRight(ElementaryRight.BROKERCONTRACTRELATION_CREATE)
    );
  }

  objectCreated(object: any) {
    return true;
  }
}
