<nav class="navbar topnav-navbar-top-logo navbar-fixed-top">
    <button id="sidebar-toggler" class="hidden-md-up" type="button" (click)="sidebarToggler()">
        &#9776;
    </button>
    <a class="hidden-md-up theme-name" href="Javascript:void(0);" (click)="gotoHome()"><strong>{{appConfigService.displayName}} p0rtal!</strong></a>
    <div class="collapse navbar-toggleable-sm">
      <a class="" href="Javascript:void(0);"(click)="gotoHome()" class="pull-left">
        <img src="assets/images/portal-top-logo.png" style="width: auto; height: 30px; margin: 15px 0 0 15px"/>
      </a>
      <ul class="nav navbar-nav pull-left" style="position: absolute; margin-top: 25px; margin-left: 250px;">
          <li class="nav-item profile">
              <failed-login-alert></failed-login-alert>
          </li>
      </ul>
      <portal-top-nav-info></portal-top-nav-info>
      <ul class="nav navbar-nav pull-right navbar-right" style="margin-top: 25px;">
        <li class="nav-item profile">
          <div class="btn-group" style="max-width: 220px; height: 20px; overflow: hidden">
            <b>{{loggedUserService.getLoggedUserData().company.registrationName}}</b>
          </div>
        </li>
        <ng-content></ng-content>
        <li class="nav-item profile" style="margin-right: 0;">
          <div class="btn-group" dropdown>
            <a class="user-name" dropdownToggle>
                <img src="assets/images/flat-avatar.png" class="topnav-img" alt="">
                <span class="hidden-md-down">{{getName()}} </span>
                <span>({{roles}})</span>
            </a>
            <ul class="dropdown-menu" aria-labelledby="lang">
              <li role="menuitem"><a class="dropdown-item" href="javascript:void(0)" (click)="goToPassChange()" translate="changePassword.title"></a></li>
              <li role="menuitem"><a class="dropdown-item" href="javascript:void(0)" (click)="gotoLogin()">{{ 'Logout' | translate }}</a></li>
            </ul>
          </div>
        </li>
        <li class="nav-item profile" style="padding-right: 15px;">
          <user-language></user-language>
        </li>
        <li class="nav-item profile" style="width: 145px;">
          <portal-session-timer (timeUp)="gotoLogin()"></portal-session-timer>
        </li>
      </ul>
    </div>
</nav>
<change-password></change-password>
<ng-container>
  <password-expiring></password-expiring>
</ng-container>

