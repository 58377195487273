import {forkJoin as observableForkJoin} from 'rxjs';
import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {
  CompanyBaseDto,
  CompanyFinancialDataVersionDto,
  DictionaryBaseDto,
  DictionaryDto,
} from '../../bonding_shared/model/dtos';
import {CompanyFinancialDataService} from '../../bonding_shared/services/company-financial-data.service';
import {DictionaryService} from '../../bonding_shared/services/dictionary.service';
import {CompanyFinancialData, DataItem} from './model/company-financial-data';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {NgForm} from '@angular/forms';
import {
  AmountUnit,
  CompanyElementaryRight,
  CompanyFinancialDataFormat,
  CompanyFinancialDataVersionType,
  DictionaryProfile,
  RatingCategory,
  RatingType,
} from '../../bonding_shared/model/dictionary-ids';
import {Currency} from '../../bonding_shared/model/currency';
import {GrowlService} from '../../bonding_shared/services/growl/growl.service';
import {DateRangeUtils} from '../../bonding_shared/utils/date-range-utils';
import {CompanyFinancialDataDto} from '../../bonding_shared/model';
import {CompanyFinancialDataRatioCategory} from './model/company-financial-data-ratios-distribution';
import {AppConfigService, DictionaryBaseService, LoggedUserService, RouterService} from '../../bonding_shared/services';
import {ConfirmDialogComponent} from '../../bonding_shared/components/confirm-dialog';
import {DateUtils} from '../../bonding_shared/utils/date-utils';
import {ComboItem} from '../../bonding_shared/components/combos';
import {StringUtils} from '../../bonding_shared/utils';

@Component({
  selector: 'company-financial-data',
  templateUrl: './company-financial-data.component.pug',
})
export class CompanyFinancialDataComponent extends DetailsView implements OnInit {
  @ViewChild(ConfirmDialogComponent, {static: true}) confirmDialog: ConfirmDialogComponent;
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;
  companyId: number;
  versionTypeFilter: DictionaryBaseDto;
  periodFilter: DictionaryBaseDto;
  fd: CompanyFinancialData;
  financialData: CompanyFinancialDataDto;
  selectedVersion: CompanyFinancialDataVersionDto;
  selectedVersionIdx: number;

  currencies: DictionaryBaseDto[];
  currency: DictionaryBaseDto;

  ratiosCategories = new CompanyFinancialDataRatioCategory();

  financialProfile = DictionaryProfile.COMPANY_FINANCIAL_DATA;
  sortValues: ComboItem[] = [new ComboItem('right', 'common.right'), new ComboItem('left', 'common.left')];
  sortValue = 'right';
  amountUnitFilter: DictionaryBaseDto;
  manual = false;
  biSource: DictionaryBaseDto;
  self = this;

  constructor(
    private companyService: CompanyFinancialDataService,
    private dictionaryService: DictionaryService,
    private dictionaryBaseService: DictionaryBaseService,
    private _route: ActivatedRoute,
    protected growlService: GrowlService,
    public router: RouterService,
    public appService: AppConfigService,
    private loggedUserService: LoggedUserService
  ) {
    super(growlService);
  }

  ngOnInit() {
    this.form = this.ngForm.form;
    this._route.params.subscribe((params) => this.initializeView(params));
    this.hideButtons(false);
    this.deleteButton.hidden = true;
    this.newVersionButton.hidden = true;
    this.handleButtons();
  }

  initializeView(params: Params) {
    const id = +params['id'];
    this.companyId = id;
    this.loadFinancialData(id);
    this.companyService.getExchangeableCurrencies().subscribe((dtos) => (this.currencies = dtos));
  }

  loadFinancialData(id: number) {
    if (id > 0) {
      observableForkJoin([
        this.companyService.getFinancialData(
          id,
          this.manual,
          this.biSource && !this.manual ? this.biSource.id : undefined,
          undefined,
          undefined,
          undefined,
          this.amountUnitFilter ? this.amountUnitFilter.id : undefined,
          undefined
        ),
        this.dictionaryService.getTypedDictionary('CompanyFinancialDataType'),
        this.dictionaryService.getTypedDictionary('CompanyFinancialRatioType'),
        this.dictionaryService.getTypedDictionary('CompanyFinancialDataFormat'),
      ]).subscribe({
        next: (res) => {
          this.financialData = res[0];
          this.fd = new CompanyFinancialData(
            res[0],
            <DictionaryDto[]>res[1],
            <DictionaryDto[]>res[2],
            <DictionaryDto[]>res[3]
          );
        },
        error: (error) => this.handleServerError(error),
      });
    }
  }

  reloadCompanyData(targetCurrencyId: number) {
    this.clearErrors();
    this.companyService
      .getFinancialData(
        this.companyId,
        this.manual,
        this.biSource && !this.manual ? this.biSource.id : undefined,
        undefined,
        undefined,
        targetCurrencyId,
        this.amountUnitFilter ? this.amountUnitFilter.id : undefined,
        undefined
      )
      .subscribe(
        (res) => {
          this.fd.reloadData(res);
          this.financialData = res;
          this.selectedVersion = undefined;
          this.selectedVersionIdx = undefined;
          this.filterFinancialData();
          this.handleButtons();
        },
        (error) => this.handleServerError(error)
      );
  }

  filterFinancialData() {
    this.fd.filter(this.financialData, this.versionTypeFilter, this.periodFilter);
  }

  onSave() {
    if (!this.form.valid) {
      this.showErrors = true;
      this.showFormError();
      StringUtils.logFormInvalidFields(this.form);
      return;
    }
    this.fd.setValuesInEditedVersion(this.selectedVersion);
    this.serverErrors = undefined;
    this.inProgress = true;
    this.companyService.saveFinancialDataVersion(this.selectedVersion).subscribe({
      next: (data) => {
        this.selectedVersion = data;
        this.inProgress = false;
        console.log('data saved:', data);
        this.showSavedMsg();
        this.askIfCalculateRating().then((generate) => {
          if (generate) {
            this.router.toCompanyRating(RatingType.BONDING, RatingCategory.BONDING, this.companyId, true);
          } else {
            this.reloadCompanyData(null);
            this.currency = undefined;
          }
        });
      },
      error: (error) => this.handleServerError(error),
    });
  }

  onCancel() {
    super.onCancel();
    this.selectedVersion = undefined;
    this.selectedVersionIdx = undefined;
    this.fd.initializeTableData();
    this.handleButtons();
  }

  onCreateNewVersion() {
    this.selectedVersion = <CompanyFinancialDataVersionDto>{};
    this.selectedVersion.validFrom = new Date();
    this.selectedVersion.company = <CompanyBaseDto>{};
    this.selectedVersion.company.id = this.companyId;
    this.selectedVersion.type = <DictionaryBaseDto>{};
    this.selectedVersion.type.id = CompanyFinancialDataVersionType.UNITARY;
    this.dictionaryBaseService.getDictionaryBase('CompanyFinancialDataFormat').subscribe((formats) => {
      this.selectedVersion.format = formats[0];
      this.dataFormatChanged(formats[0]);
    });
    this.selectedVersion.currency = <DictionaryBaseDto>{};
    this.selectedVersion.currency.id = Currency.PLN_ID;
    this.selectedVersion.unit = <DictionaryBaseDto>{};
    this.selectedVersion.unit.id = AmountUnit.ONE;
    this.selectedVersion.values = [];
    this.fd.initializeDictionaryItemsForEdition(this.selectedVersion);
    this.handleButtons();
  }

  onVersionSelected(v: {index: number; selected: boolean}) {
    if (v.selected) {
      this.selectedVersionIdx = v.index;
    } else {
      this.selectedVersionIdx = undefined;
    }
    this.handleButtons();
  }

  onHeaderClick(columnIdx: number) {
    if (columnIdx > 0) {
      this.selectedVersionIdx = columnIdx;
      this.onEdit();
    }
  }

  unitLabel(unit: DictionaryBaseDto) {
    if (!unit) {
      return '';
    }
    switch (unit.id) {
      case AmountUnit.THOUSAND:
        return '1.000 ';
      case AmountUnit.MILION:
        return '1.000.000 ';
      default:
        return '1 ';
    }
  }

  versionTypeFilterChanged(type: DictionaryBaseDto) {
    this.versionTypeFilter = type;
    this.filterFinancialData();
  }

  detailsVersionTypeChanged() {
    if (!this.selectedVersion.period) {
      this.selectedVersion.validFrom = new Date();
      this.selectedVersion.validTo = undefined;
      this.selectedVersion.period = undefined;
    } else {
      this.detailsPeriodChanged(this.selectedVersion.period);
    }
  }

  periodFilterChanged(period: DictionaryBaseDto) {
    this.periodFilter = period;
    this.filterFinancialData();
  }

  detailsPeriodChanged(period: DictionaryBaseDto) {
    if (period) {
      const range = DateRangeUtils.calculatePreviousPeriod(new Date(), period);
      this.selectedVersion.validFrom = range.dateFrom;
      this.selectedVersion.validTo = range.dateTo;
    } else {
      this.selectedVersion.validTo = DateRangeUtils.calculatePeriodEnd(this.selectedVersion.validFrom, period);
    }
  }

  validFromChanged(date: Date) {
    if (this.selectedVersion.validFrom && this.selectedVersion.period) {
      this.selectedVersion.validTo = DateRangeUtils.calculatePeriodEnd(
        this.selectedVersion.validFrom,
        this.selectedVersion.period
      );
    }
  }

  dataFormatChanged(format: DictionaryBaseDto) {
    this.fd.initializeDictionaryItemsForEdition(this.selectedVersion);
  }

  amountUnitFilterChanged(unit: DictionaryBaseDto) {
    this.amountUnitFilter = unit;
    this.reloadCompanyData(this.currency ? this.currency.id : undefined);
  }

  onEdit() {
    if (!this.selectedVersionIdx) {
      return;
    }
    const versionNumber = this.fd.versions.length - this.selectedVersionIdx + 1;

    this.companyService
      .getFinancialDataVersion(this.fd.initialFinancialData.versions[this.selectedVersionIdx - 1].id)
      .subscribe((v) => {
        this.selectedVersion = v;
        this.fd.initializeDictionaryItemsForEdition(this.selectedVersion);
        this.handleButtons();
      });
  }

  onChangeCurrency(currency: DictionaryBaseDto) {
    this.reloadCompanyData(currency ? currency.id : undefined);
  }

  handleButtons() {
    this.saveButton.hidden = !this.selectedVersion;
    this.saveButton.disabled = !this.loggedUserService.hasRight(CompanyElementaryRight.COMPANY_FINANCIAL_DATA_EDIT);
    this.cancelButton.hidden = !this.selectedVersion;
    this.cancelButton.disabled = !this.loggedUserService.hasRight(CompanyElementaryRight.COMPANY_FINANCIAL_DATA_EDIT);
    this.newVersionButton.hidden = !!this.selectedVersion;
    this.newVersionButton.disabled = !this.loggedUserService.hasRight(
      CompanyElementaryRight.COMPANY_FINANCIAL_DATA_EDIT
    );
  }

  categorize(ratios: DataItem[], ratioCategoryId: number): DataItem[] {
    return ratios.filter((r) => this.ratiosCategories.categorize(r.dictionary.parentId, ratioCategoryId));
  }

  ratioCategories(): number[] {
    return this.ratiosCategories.categories_FIDAT;
  }

  ratioCategoryName(distributionId: number): string {
    return this.ratiosCategories.names[distributionId];
  }

  askIfCalculateRating(): Promise<boolean> {
    return new Promise((resolve, reject) => resolve(false));
  }

  isFIDATFinData(): boolean {
    const versions = this.fd.initialFinancialData.versions;
    if (versions && versions.length > 0) {
      const version = this.fd.initialFinancialData.versions[0];
      return version.format.id === CompanyFinancialDataFormat.FIDAT;
    }
    return false;
  }

  sourceName(): string {
    if (this.selectedVersion && this.selectedVersion.biSource) {
      return this.selectedVersion.biSource.name;
    } else {
      return 'Manual';
    }
  }

  floor(value: number): number {
    return !!value ? Math.floor(value) : value;
  }

  onSortChange(e: any) {
    this.financialData.versions = this.financialData.versions.sort((v1, v2) =>
      CompanyFinancialDataComponent.compare(v1.validTo, v2.validTo, this.sortValue)
    );
    this.fd.versions = this.fd.versions.sort((v1, v2) =>
      CompanyFinancialDataComponent.compare(v1.version.validTo, v2.version.validTo, this.sortValue)
    );
  }

  biSourceChanged(source: DictionaryBaseDto) {
    this.reloadCompanyData(this.currency ? this.currency.id : undefined);
  }

  manualStateChanged(state: boolean) {
    this.reloadCompanyData(this.currency ? this.currency.id : undefined);
  }

  // tslint:disable-next-line:member-ordering
  private static compare(date1: Date, date2: Date, value: string): number {
    if (value && value.localeCompare('right')) {
      return DateUtils.compare(date1, date2);
    } else {
      return -DateUtils.compare(date1, date2);
    }
  }
}
