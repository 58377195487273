import {Inject, Injectable} from '@angular/core';
import {ApiConfig, ApiConfigProfile, AppConfig} from '../model/index';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class AppConfigService {
  backendUrl: string;
  displayName: string;
  bondCertLink: string;
  themeColour: string;
  helpLink: string;
  envCode: 'DEV' | 'DEMO' | 'UAT' | 'PROD';

  performInitialSearch = true;
  oauth2 = false;

  constructor(@Inject('app.config') appConfig: AppConfig, private http: HttpClient) {
    console.log('Injected config: ', appConfig);

    const apiConfig: ApiConfig = appConfig ? appConfig.apiConfig : new ApiConfig();
    let configProfile: ApiConfigProfile = apiConfig.selectedProfile
      ? apiConfig.profiles[apiConfig.selectedProfile]
      : new ApiConfigProfile();

    const useInfix: boolean = window.location.origin.split(':').length > 2;

    const defaultPrefix: string = window.location.origin;
    const defaultInfix = 'isa';
    const defaultSuffix = 'rest';

    if (apiConfig.selectedProfile) {
      configProfile = apiConfig.profiles[apiConfig.selectedProfile];
    }

    if (configProfile.directBackendUrl) {
      this.backendUrl = configProfile.directBackendUrl;
    } else {
      if (configProfile.useSuffix === undefined) {
        configProfile.useSuffix = true;
      }
      if (!configProfile.useSuffix && !useInfix) {
        configProfile.useSuffix = true;
      }
      if (
        !configProfile.backendUrlPrefix ||
        configProfile.backendUrlPrefix.indexOf('backend.url.prefix') !== -1 ||
        configProfile.backendUrlPrefix === 'default'
      ) {
        configProfile.backendUrlPrefix = defaultPrefix;
      }
      if (
        !configProfile.backendUrlInfix ||
        configProfile.backendUrlInfix.indexOf('backend.url.infix') !== -1 ||
        configProfile.backendUrlInfix === 'default'
      ) {
        configProfile.backendUrlInfix = defaultInfix;
      }
      if (
        !configProfile.backendUrlSuffix ||
        configProfile.backendUrlSuffix.indexOf('backend.url.suffix') !== -1 ||
        configProfile.backendUrlSuffix === 'default'
      ) {
        configProfile.backendUrlSuffix = defaultSuffix;
      }
      this.backendUrl =
        configProfile.backendUrlPrefix +
        (useInfix ? '/' + configProfile.backendUrlInfix : '') +
        (configProfile.useSuffix ? '/' + configProfile.backendUrlSuffix : '') +
        '/';
    }
    console.log('Backend URL resolved to ', this.backendUrl);

    if (configProfile.themeColour) {
      this.themeColour = configProfile.themeColour;
    }

    if (configProfile.displayName && configProfile.displayName.indexOf('display.name') === -1) {
      this.displayName = configProfile.displayName;
    }
    if (configProfile.bondCertLink && configProfile.bondCertLink.indexOf('bond.cert.link') === -1) {
      this.bondCertLink = configProfile.bondCertLink;
    } else {
      this.bondCertLink = window.location.origin + '/bond_certs/';
    }
    this.envCode = configProfile.envCode;

    if (appConfig.oauth2) {
      this.oauth2 = true;
    }
    if (appConfig.performInitialSearch !== undefined) {
      this.performInitialSearch = appConfig.performInitialSearch;
    }

    if (configProfile.helpLink) {
      this.helpLink = configProfile.helpLink;
    }
  }
}
