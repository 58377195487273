import {CompanySearchComponent} from './company-search.component';
import {CompanyDetailsComponent} from './company-details.component';
import {CompanyPreviewComponent} from './company-preview.component';
import {CompanyRatingComponent} from './company-rating.component';
import {CompanyFinancialDataComponent} from './company-financial-data.component';
import {CompanyCRMComponent} from './company-crm.component';
import {CompanyManagersComponent} from './company-managers.component';
import {CompanyAccessGuard} from './_guards/company-access.guard';
import {CompanyDetailAccessGuard} from './_guards/company-detail-access.guard';
import {CompanySearchGuard} from './_guards/company-search.guard';
import {CompanyBISearchGuard} from './_guards/company-bi-search.guard';
import {CompanyRatingDetailAccessGuard} from './_guards/company-rating-detail-access.guard';
import {CompanyManagersAccessGuard} from './_guards/company-managers-access.guard';
import {CompanyFinancialDataAccessGuard} from './_guards/company-financial-data-access.guard';
import {CompanyExternalProvidersComponent} from './company-external-providers.component';

export const CompanyRoutes = [
  {
    path: 'company-details',
    component: CompanyDetailsComponent,
    canActivate: [CompanyAccessGuard, CompanyDetailAccessGuard],
  },
  {
    path: 'company-details/:id',
    component: CompanyDetailsComponent,
    canActivate: [CompanyAccessGuard, CompanyDetailAccessGuard],
  },
  {
    path: 'company-preview/:id',
    component: CompanyPreviewComponent,
    canActivate: [CompanyAccessGuard],
  },
  {
    path: 'company-preview/:source/:countryId/:id',
    component: CompanyPreviewComponent,
    canActivate: [CompanyAccessGuard],
  },
  {
    path: 'company-search',
    component: CompanySearchComponent,
    canActivate: [CompanyAccessGuard, CompanySearchGuard],
  },
  {
    path: 'company-search/:source',
    component: CompanySearchComponent,
    canActivate: [CompanyAccessGuard, CompanyBISearchGuard],
  },
  {
    path: 'company-rating/:ratingType/:ratingCategoryId/:id',
    component: CompanyRatingComponent,
    canActivate: [CompanyRatingDetailAccessGuard, CompanyAccessGuard],
  },
  {
    path: 'company-rating/:ratingCategoryId/:id',
    component: CompanyRatingComponent,
    canActivate: [CompanyRatingDetailAccessGuard, CompanyAccessGuard],
  },
  {
    path: 'company-financial-data/:id',
    component: CompanyFinancialDataComponent,
    canActivate: [CompanyAccessGuard, CompanyFinancialDataAccessGuard],
  },
  {
    path: 'company-crm',
    component: CompanyCRMComponent,
    canActivate: [CompanyAccessGuard],
  },
  {
    path: 'company-managers/:id',
    component: CompanyManagersComponent,
    canActivate: [CompanyAccessGuard, CompanyManagersAccessGuard],
  },
  {
    path: 'company-external-providers/:companyId',
    component: CompanyExternalProvidersComponent,
  },
];
