<details-view [title]="'policy.technicalDetails.title'" objectName="Policy" [parentComponent]="self"
              [versionListHidden]="true" [fixedBlockHidden]="false" [objectCreated]="objectCreated"
              [updateRightOrFunc]="PolicyElementaryRight.POLICY_SERVICE_DETAILS_UPDATE" [object]="technicalDetails"
              >
  <div class="details-content">
    <form #ngForm="ngForm">
      <div class="bon-label-190">
        <bon-section labelKey="policy.technicalDetails.attendants">
          <div class="bon-card-group">
            <div class="bon-card-inner bon-label-270">
              <ng-template #spinner>
                <i class="fa fa-spinner fa-spin"></i>
              </ng-template>

              <form-row labelKey="policy.technicalDetails.underwriter"  [showErrors]="showErrors">
                <item-combo class="bon-input-size" *ngIf="underwriters; else spinner" [items]="underwriters" [(ngModel)]="technicalDetails.attendants.underwriter" name="underwriter"
                            [presentationMode]="portal"
                            nullLabel=" ">
                  <ng-template let-item="item">{{item | username}}</ng-template>
                </item-combo>
              </form-row>
              <form-row labelKey="policy.technicalDetails.manager"  [showErrors]="showErrors">
                <item-combo class="bon-input-size" *ngIf="managers; else spinner" [items]="managers" [(ngModel)]="technicalDetails.attendants.manager" name="manager"
                            [presentationMode]="portal"
                            nullLabel=" ">
                  <ng-template let-item="item">{{item | username}}</ng-template>
                </item-combo>
              </form-row>
            </div>
          </div>
        </bon-section>

        <bon-section labelKey="policy.technicalDetails.lifecycleStage">
          <div class="bon-card-group">
            <div class="bon-card-inner bon-label-270">
              <form-row labelKey="policy.technicalDetails.lifecycleStage"  [showErrors]="showErrors">
                <dict-combo [(ngModel)]="technicalDetails.lifecycleStage" dictionary="PolicyLifecycleStage" class="bon-input-size"
                            nullLabel=" " [presentationMode]="portal" name="lifecycleStage"></dict-combo>
              </form-row>
            </div>
          </div>
        </bon-section>

        <bon-section labelKey="policy.technicalDetails.lifecycleStageRevision">
          <div class="bon-card-group">
            <div class="bon-card-inner">
              <a-table [items]="revisions"  [canUnselect]="false" [spacer]="false">
                <column property="changeTimestamp" labelKey="common.changeTime" type="datetime" initialSort="down"></column>
                <column labelKey="common.changedBy">
                  <ng-template let-item="item">
                    <span>{{item.user | username}}</span>
                  </ng-template>
                </column>
                <column property="lifecycleStage" labelKey="policy.technicalDetails.lifecycleStage" dictionary="PolicyLifecycleStage"></column>
              </a-table>
            </div>
          </div>
        </bon-section>

        <bon-section labelKey="policy.technicalDetails.brokerContracts">
          <div class="bon-card-group">
            <div class="bon-card-inner">
              <broker-contract-relation-list *ngIf="technicalDetails" [relatedToId]="technicalDetails.policyContract.masterPolicyContract.id" [relatedToTypeId]="'19000021'" [showBrokerColumns]="true">
              </broker-contract-relation-list>
            </div>
          </div>
        </bon-section>

        <bon-section labelKey="policy.technicalDetails.salesRep">
          <div class="bon-card-group">
            <div class="bon-card-inner">
              <sales-rep-relation-policy-list *ngIf="technicalDetails" [relatedToId]="technicalDetails.policyContract.masterPolicyContract.id">
              </sales-rep-relation-policy-list>
            </div>
          </div>
        </bon-section>

        <bon-section labelKey="policy.technicalDetails.cessions" *ngIf="showCession()">
          <div class="bon-card-group">
            <div class="bon-card-inner">
                <policy-cession-list [masterPolicyContractId]="technicalDetails.policyContract.masterPolicyContract.id" [policyContractId]="technicalDetails.policyContract.id"></policy-cession-list>
            </div>
          </div>
        </bon-section>
        <bon-section labelKey="policy.monitoring.monitoring" *ngIf="showMonitoring()">
          <div class="bon-card-group">
            <div class="bon-card-inner">
              <policy-monitoring-list [policyContractId]="technicalDetails.policyContract.id" [productTypeId]="technicalDetails.policyContract.masterPolicyContract.productType.id"></policy-monitoring-list>
            </div>
          </div>
        </bon-section>
    </div>
    </form>
  </div>
  <div class="additional-buttons">
    <button class="bon-btn-warning" *ngIf="addRelationButtonVisible()" type="button"
    (click)="routerService.toBrokerContractRelationDetailsForPolicy(0, technicalDetails.policyContract.masterPolicyContract.id, 0)">
      {{'policy.technicalDetails.addBroker' | translate}}
    </button>
    <ng-container *hasRight="SalesRepElementaryRight.SALES_REP_RELATION_CREATE_UPDATE_POLICIES">
      <button class="bon-btn-warning" *ngIf="technicalDetails" type="button"
              (click)="routerService.toSalesRepRelationDetailsForPolicy(undefined, technicalDetails.policyContract.masterPolicyContract.id)">
        {{'policy.technicalDetails.addSalesRep' | translate}}
      </button>
    </ng-container>
    <ng-container *hasRight="PolicyElementaryRight.POLICY_CESSION_CREATE">
      <button class="bon-btn-warning" *ngIf="showCession()" type="button"
              (click)="routerService.toCessionDetailsNew(technicalDetails.policyContract.id)">
        {{'policy.technicalDetails.addCession' | translate}}
      </button>
    </ng-container>
  </div>
  <div class="information-header">
    <div style="float: left; margin-top: 2px;">
      <span style="float: left;" *ngIf="technicalDetails">
        <select class="bon-select" [ngModel]="technicalDetails.policyContract.policyYear"
                (change)="onChangePolicyYear($event.target.value)">
          <option *ngFor="let item of policyContracts" value="{{item.policyYear}}">{{'policy.year' | translate}} {{item.policyYearFormatted}}</option>
        </select>
      </span>
    </div>
  </div>
</details-view>
