<search-view objectName="Template" customTitle="searchView.templateSearch"
             [dataProvider]="searchDataProvider" [searchModeSwitchable]="false" [newButton]="false">
  <div class="search-criteria bon-card-group">
    <div class="bon-card-inner" style="width: 30%">
      <input-row labelKey="template.search.templateName" [(ngModel)]="criteria.name" name="name"></input-row>
      <input-row labelKey="template.search.company" [(ngModel)]="criteria.client.registrationName" name="clientName"></input-row>
    </div>
    <div class="bon-card-inner">
      <dict-row labelKey="template.search.documentType" [(ngModel)]="criteria.type" name='documentType' dictionary='GeneratedDocumentType' nullLabel=' '></dict-row>
      <dict-row labelKey="template.search.relatedObject" [(ngModel)]="criteria.relatedTo" name='relatedTo' dictionary='BusinessObjectType'
                nullLabel=' '></dict-row>
    </div>
    <div class="bon-card-inner">
      <div class="bon-row">
        <label class="bon-label" translate>template.search.businessUnit</label>
        <business-unit-id-selector class="bon-input-size" [(ngModel)]="criteria.businessUnit"
                                name="businessUnit" nullLabelKey="All"></business-unit-id-selector>
      </div>
      <dict-row labelKey="template.search.language" [(ngModel)]="criteria.language" name='language' dictionary='Language' nullLabel=' '></dict-row>
    </div>
    <div class="bon-card-inner"></div>
  </div>
  <div class="search-results">
    <a-table [pagination]="true" [dataProvider]="searchDataProvider">
      <column property="name" sort="name" [link]="true" (linkClick)="gotoTemplateDetail($event)" initialSort="up"></column>
      <column property="relatedTo" sort="relatedTo.name" dictionary="BusinessObjectType"></column>
      <column property="type" sort="type.name" dictionary="GeneratedDocumentType"></column>
      <column property="language" sort="language.name" dictionary="Language"></column>
      <column property="businessUnit.name" title="Business unit"></column>
      <column property="client.registrationName" sort="client.registrationName" title="Client"></column>
    </a-table>
  </div>
</search-view>
