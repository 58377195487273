import {Component, OnInit, ViewChild} from '@angular/core';
import {RouterService} from '../../../bonding_shared/services/router-service';
import {SearchViewComponent} from '../../../bonding_shared/components/search/search-view/search-view.component';
import {
  CompanySimpleDto,
  DictionaryBaseDto,
  MasterPolicyContractForLimitDto,
  PolicyContractVersionCriteriaDto,
  PolicyContractVersionDto,
  ProductLimitListVersionCriteriaDto,
  ProductLimitListVersionSimpleDto,
} from '../../../bonding_shared/model/dtos';
import {
  LoggedUserService,
  PolicyContractVersionService,
  PolicyLimitListService,
} from '../../../bonding_shared/services';
import {PolicyLimitListGuiService} from '../services/policylimitlist-gui.service';
import {PolicyLimitListListComponent} from './policylimitlist-list.component';
import {ListEmitters} from '../../../bonding_shared/components/details-view/list-emitters';
import {TextSearchCriteria} from '../../../bonding_shared/components/search/model/text-search-criteria';
import {ActivatedRoute, Params} from '@angular/router';
import {
  CustomFieldValueType,
  LimitCategory,
  PolicyContractVersionStatus,
  PolicyElementaryRight,
} from '../../../bonding_shared/model/dictionary-ids';
import {BackendError} from '../../../bonding_shared/model';

@Component({
  selector: 'policylimitlist-search',
  templateUrl: './policylimitlist-search.component.pug',
})
export class PolicyLimitListSearchComponent extends ListEmitters implements OnInit {
  readonly PolicyElementaryRight = PolicyElementaryRight;
  serverErrors: BackendError;

  @ViewChild(SearchViewComponent, {static: true}) searchView: SearchViewComponent<
    ProductLimitListVersionCriteriaDto,
    ProductLimitListVersionSimpleDto
  >;
  @ViewChild(PolicyLimitListListComponent, {static: true}) policyLimitListList: PolicyLimitListListComponent;
  policySearchCriteria: PolicyContractVersionCriteriaDto = <PolicyContractVersionCriteriaDto>{};
  policyTextCriteria: TextSearchCriteria = new TextSearchCriteria();
  potentialOwners: CompanySimpleDto[] = [];
  CustomFieldValueType = CustomFieldValueType;
  portalMode = false;
  fixedPolicy = false;
  isVersionRejected = false;

  constructor(
    public guiService: PolicyLimitListGuiService,
    private policyLimitListService: PolicyLimitListService,
    private policyContractVersionService: PolicyContractVersionService,
    public router: RouterService,
    private _route: ActivatedRoute,
    private loggedUserService: LoggedUserService
  ) {
    super();
    this.initializeSelectorEmittersByNames(true, ['Policy']);
    this.portalMode = loggedUserService.portal;
    this.policyTextCriteria.last = true;
    this.policyTextCriteria.lastPolicyYear = true;
  }

  ngOnInit() {
    this._route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    const masterPolicyId = +params['masterPolicyId'];
    this.serverErrors = undefined;
    if (masterPolicyId > 0) {
      this.fixedPolicy = true;
      this.setMasterPolicy(masterPolicyId);
    }
  }

  setMasterPolicy(masterPolicyId: number) {
    this.policyLimitListService.getMasterPolicy(masterPolicyId).subscribe(
      (mpc) => {
        this.guiService.searchCriteria.setPolicy(mpc);
        this.potentialOwners = [mpc.client];
        this.potentialOwners.push(...mpc.subinsureds);
        this.policyLimitListList.search();
      },
      (error) => {
        this.serverErrors = error;
        console.log('get master policy error', error);
      }
    );
  }

  setPolicyVersion(pcv: PolicyContractVersionDto) {
    this.setMasterPolicy(pcv.policyContract.masterPolicyContract.id);
  }

  clearMasterPolicy() {
    console.log('clearMasterPolicy');
    this.guiService.searchCriteria.setPolicy(undefined);
  }

  getPolicyNumber(p: MasterPolicyContractForLimitDto) {
    return p ? p.number : undefined;
  }

  get masterPolicy(): MasterPolicyContractForLimitDto {
    return this.guiService.searchCriteria.masterPolicy;
  }

  get dataProvider() {
    return this.guiService.searchCriteria.dataProvider;
  }

  get criteria() {
    return this.guiService.searchCriteria.dataProvider.searchCriteria.criteria;
  }
}
