<a-table [pagination]="true" [dataProvider]="dataProvider" [selection]="false">
  <column *ngIf="showId" labelKey="common.id" property="id" sort="id"></column>
  <column *ngIf="showCompany" labelKey="biReport.company.id" property="company.id" sort="company.id" [link]="true" (linkClick)="router.toCompanyPreview($event.company.id)"></column>
  <column *ngIf="showCompany" labelKey="biReport.company.name" property="company.registrationName" sort="company.name"></column>
  <column *ngIf="showCompanyRaw" labelKey="biReport.companyRaw.id" property="companyRaw.id" sort="companyRaw.id"></column>
  <column *ngIf="showCompanyRaw" labelKey="biReport.companyRaw.name" property="companyRaw.registrationName" sort="companyRaw.name"></column>
  <column *ngIf="showThirdParty" labelKey="biReport.thirdPartyId" property="thirdParty.id" [link]="true" (linkClick)="router.toThirdPartyDetails($event.thirdParty.id)"></column>
  <column *ngIf="showCountry" labelKey="common.country" property="country.name"></column>

  <column labelKey="documentList.reportType" property="type" sort="type" dictionary="BIReportType"  [link]="!showId" (linkClick)="router.toBiReport($event.id)"></column>
  <column labelKey="documentList.source" property="source" sort="source" dictionary="BISource"></column>
  <column labelKey="common.status" property="status" sort="status" dictionary="BIReportStatus"></column>
  <column labelKey="documentList.deliveryTypeDescription" property="deliveryTypeDescription"></column>
  <column labelKey="documentList.orderTime" property="orderTime" sort="orderTime"
          type="datetime" initialSort="down"></column>
  <column labelKey="documentList.receiptTime" property="document.creationDate" type="datetime">
    <ng-template let-item="item">
      <span *ngIf="item.pdfDocument">{{item.pdfDocument.creationDate | date}}</span>
      <span *ngIf="!item.pdfDocument && item.csvDocument">{{item.csvDocument.creationDate | date}}</span>
      <span *ngIf="!item.pdfDocument && !item.csvDocument && item.excelDocument">{{item.excelDocument.creationDate | date}}</span>
    </ng-template>
  </column>
  <column labelKey="documentList.createdBy">
    <ng-template let-item="item">
      {{item.user | username}}
    </ng-template>
  </column>
  <column property="document.status" dictionary="DocumentStatus" dictLabel="name" labelKey="documentList.status"></column>
  <column property="externalId" labelKey="common.externalId"></column>
  <column title="Pdf" [link]="true">
    <ng-template let-item="item">
      <a *ngIf="item.pdfDocument" (click)="gotoDocumentDetail(item.pdfDocument)" target="_blank">{{item.pdfDocument.id}}.{{item.pdfDocument.extension || 'pdf'}}</a>
    </ng-template>
  </column>
  <column labelKey="biReport.rejectReason" property="rejectReason"></column>

</a-table>
