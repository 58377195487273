import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {InquiryDetailsComponent} from './inquiry-details.component';
import {InquirySearchComponent} from './inquiry-search.component';
import {InquiryGuiService} from './services/inquiry-gui.service';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';
import {OfferDetailsComponent} from './offer-details.component';

import {BondSharedModule} from '../bond/components/shared/bond-shared.module';
import {CustomFormModule} from '../../common/custom-form/custom-form.module';
import {BrokerContractSharedModule} from '../broker-contract/components/shared/broker-contract-shared.module';
import {PolicyContractSharedModule} from '../policy/components/shared/policy-contract-shared.module';
import {TabViewModule} from 'primeng/tabview';
import {CompanyExtendedSelectorModule} from '../company/components/shared/extended-selectors/company-extended-selector.module';
import {SalesRepSharedModule} from '../sales-rep/components/shared/sales-rep-shared.module';
import {InquirySharedModule} from './shared/inquiry-shared.module';
import {VisibilityService} from './services/visibility.service';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {BusinessObjectInfoModule} from '../business-object-info/business-object-info.module';
import {InquiryFormsModule} from './forms/inquiry-forms.module';
import {InquiryDetailsGuard, InquiryOfferDetailsGuard} from './_guards';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    BondSharedModule,
    CustomFormModule,
    BrokerContractSharedModule,
    CustomFormModule,
    PolicyContractSharedModule,
    TabViewModule,
    CompanyExtendedSelectorModule,
    SalesRepSharedModule,
    InquirySharedModule,
    BsDropdownModule,
    BusinessObjectInfoModule,
    InquiryFormsModule,
  ],
  declarations: [InquiryDetailsComponent, InquirySearchComponent, OfferDetailsComponent],
  exports: [],
  providers: [InquiryGuiService, VisibilityService, InquiryDetailsGuard, InquiryOfferDetailsGuard],
})
export class InquiryModule {}
