import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {DocumentModule} from '../document/document.module';
import {LimitSearchComponent} from './limit-search.component';
import {LimitDetailsComponent} from './limit-details.component';
import {PolicyModule} from '../policy/policy.module';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';
import {LimitSharedModule} from './shared/limit-shared.module';
import {PolicyContractSharedModule} from '../policy/components/shared/policy-contract-shared.module';
import {InquiryModule} from '../inquiry/inquiry.module';
import {CustomFormModule} from '../../common/custom-form/custom-form.module';
import {LimitValueDisplayComponent} from './components/limit-value-display.component';
import {CompanyExtendedSelectorModule} from '../company/components/shared/extended-selectors/company-extended-selector.module';
import {ImportModule} from '../import/import.module';
import {TaskSharedModule} from '../task/shared/task-shared.module';
import {ThirdPartyModule} from '../thirdparty/thirdparty.module';
import {PolicyLimitListSharedModule} from '../policy-limit-list/shared/policy-limit-list-shared.module';
import {LimitAttachmentsSectionComponent} from './components/limit-attachments-section.component';
import {LimitOverduePaymentsSectionComponent} from './components/limit-overdue-payments-section.component';
import {LimitReductionDialogComponent} from './components/limit-reduction-dialog.component';
import {LimitRequestComponent} from './components/limit-request.component';
import {LimitDecisionComponent} from './components/limit-decision.component';
import {LimitSelectorsComponent} from './shared/components/limit-selectors.component';
import {LimitCollateralsSectionComponent} from './components/limit-collaterals-section.component';
import {LimitBusinessParamsDetailsComponent} from './business-params/limit-business-params-details.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TemporaryLimitComponent} from './components/temporary-limit.component';
import {LimitAccessGuard} from './guard/limit-access-guard';
import {LimitBusinessParamsGuard} from './guard/limit-business-params-guard';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    DocumentModule,
    PolicyModule,
    LimitSharedModule,
    PolicyContractSharedModule,
    InquiryModule,
    CompanyExtendedSelectorModule,
    CustomFormModule,
    ImportModule,
    ImportModule,
    TaskSharedModule,
    ThirdPartyModule,
    PolicyLimitListSharedModule,
    BsDropdownModule,
  ],
  declarations: [
    LimitDetailsComponent,
    LimitDecisionComponent,
    LimitRequestComponent,
    LimitSearchComponent,
    TemporaryLimitComponent,
    LimitValueDisplayComponent,
    LimitAttachmentsSectionComponent,
    LimitOverduePaymentsSectionComponent,
    LimitAttachmentsSectionComponent,
    LimitCollateralsSectionComponent,
    LimitReductionDialogComponent,
    LimitSelectorsComponent,
    LimitBusinessParamsDetailsComponent,
  ],
  providers: [LimitAccessGuard, LimitBusinessParamsGuard],
})
export class LimitModule {}
