import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {
  BackendError,
  DictionaryBaseDto,
  DictionaryDto,
  ErrorCode,
  ErrorReason,
  LimitDecisionBaseDto,
  LimitDecisionDto,
  LimitDto,
  LimitRequestDto,
  LimitRequestInLimitDto,
  LimitRequestReductionDto,
  PolicyInquiryVersionSimpleDto,
  TaskWithUserDto,
  TemplateSimpleDto,
  ThirdPartyBaseDto,
} from '../../bonding_shared/model';
import {
  AppConfigService,
  CacheService,
  CompanyService,
  DictionaryService,
  FormatService,
  GrowlService,
  InquiryService,
  LimitService,
  LoggedUserService,
  PolicyContractVersionService,
  RouterService,
  TemplateService,
} from '../../bonding_shared/services';
import {ActivatedRoute} from '@angular/router';
import {StringUtils} from '../../bonding_shared/utils';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {Button} from '../../bonding_shared/components/details-view/button';
import {
  BusinessObjectType,
  ContactNoteElementaryRight,
  DocumentType,
  LimitDecisionStatus,
  LimitDecisionType,
  LimitElementaryRight,
  LimitRequestStatus,
  LimitRequestType,
  LimitStatus,
  RepoDocumentElementaryRight,
} from '../../bonding_shared/model/dictionary-ids';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmDialogComponent} from '../../bonding_shared/components/confirm-dialog';
import {Observable} from 'rxjs';
import {BusinessUtils} from '../../bonding_shared/utils/business-utils';
import {LimitReductionDialogComponent} from './components/limit-reduction-dialog.component';
import {LimitDecisionComponent} from './components/limit-decision.component';
import {LimitRequestComponent} from './components/limit-request.component';
import {LimitSelectorsComponent} from './shared/components/limit-selectors.component';
import {LimitGuiService} from './shared/services/limit-gui.service';

@Component({
  selector: 'limit-details',
  templateUrl: './limit-details.component.pug',
})
export class LimitDetailsComponent extends DetailsView implements OnInit {
  readonly RepoDocumentElementaryRight = RepoDocumentElementaryRight;

  @ViewChild('limitConfirm', {static: true}) limitConfirmDialog: ConfirmDialogComponent;
  @ViewChild('limitReductionConfirm', {static: true}) reductionDialog: LimitReductionDialogComponent;
  @ViewChild('cancelDecisionConfirm', {static: true}) limitDecisionCancelConfirmDialog: ConfirmDialogComponent;

  _decisionComponent: LimitDecisionComponent;
  get decisionComponent(): LimitDecisionComponent {
    return this._decisionComponent;
  }

  @ViewChild('decisionForm')
  set decisionComponent(cmp: LimitDecisionComponent) {
    if (cmp) {
      this._decisionComponent = cmp;
    }
  }

  get requestComponent(): LimitRequestComponent {
    return this._requestComponent;
  }

  @ViewChild('requestForm')
  set requestComponent(cmp: LimitRequestComponent) {
    if (cmp) {
      this._requestComponent = cmp;
    }
  }

  _requestComponent: LimitRequestComponent;

  @ViewChild('limitSelectors')
  set selectors(cmp: LimitSelectorsComponent) {
    console.log('limit selectors', cmp);
    if (cmp) {
      this._selectors = cmp;
    }
  }

  get selectors() {
    return this._selectors;
  }

  _selectors: LimitSelectorsComponent;

  id: number;
  fixedMasterPolicyId: number;
  viewParams: LimitViewParams;
  limitRequest: LimitRequestDto;
  limit: LimitDto; // stores the list of requests
  limitDecision: LimitDecisionDto;
  decisions: LimitDecisionDto[];
  relatedObjectsEditable = true;
  decisionTypes: DictionaryDto[] = [];
  templates: TemplateSimpleDto[] = [];
  requestTypes: DictionaryBaseDto[];
  allRequestTypes: DictionaryBaseDto[];
  newRequestButton: Button;
  cancelDecisionButton: Button;
  cancelRequestButton: Button;
  prolongDecisionButton: Button;
  limitReductionButton: Button;
  newDecisionButtonHidden = false;
  readonly BusinessObjectType = BusinessObjectType;
  readonly ContactNoteElementaryRight = ContactNoteElementaryRight;
  readonly LimitRights = LimitElementaryRight;

  constructor(
    private _route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private limitService: LimitService,
    private dictionaryService: DictionaryService,
    private companyService: CompanyService,
    private policyContractVersionService: PolicyContractVersionService,
    private templateService: TemplateService,
    public router: RouterService,
    protected translateService: TranslateService,
    private loggedUserService: LoggedUserService,
    protected growlService: GrowlService,
    protected cacheService: CacheService,
    private formatService: FormatService,
    private limitGuiService: LimitGuiService,
    private inquiryService: InquiryService,
    public appService: AppConfigService
  ) {
    super(growlService);
    this.deleteButton = undefined;
    this.newVersionButton.hidden = true;
    this.newRequestButton = new Button('limitDetails.button.newRequest', undefined, false);
    this.cancelDecisionButton = new Button(
      'limitDetails.button.cancelDecision',
      this.onCancelDecision.bind(this),
      true
    );
    this.prolongDecisionButton = new Button(
      'limitDetails.button.prolongDecision',
      this.onProlongDecision.bind(this),
      true
    );
    this.cancelRequestButton = new Button('limitDetails.button.cancelRequest', this.onCancelRequest.bind(this), true);
    this.limitReductionButton = new Button(
      'limitDetails.button.reduceLimit',
      this.openReductionDialog.bind(this),
      true
    );
  }

  ngOnInit() {
    this.dictionaryService.getDictionary('LimitRequestType').subscribe((dicts) => (this.allRequestTypes = dicts));
    this.commonInit<LimitViewParams>(this._route);
  }

  initViewWithId(id: number) {
    this.id = id;
    this.getLimitRequest(this.id);
  }

  initViewWithCustomParams(q: LimitViewParams) {
    this.limitRequest = undefined;
    this.viewParams = q;
    if (q.requestId) {
      this.getLimitRequest(+q.requestId, q.readFromCache, +q.decisionId);
    } else {
      this.hideButtons(true);
      this.refreshDecisionTypes(0);
      this.setInitialLimitData(q.readFromCache, +q.typeId);
      this.relatedObjectsEditable = true;
      this.initBuyer(+q.buyerCompanyId);
      if (q.insuredId) {
        this.initClient(+q.insuredId);
      } else if (q.masterPolicyId) {
        this.fixedMasterPolicyId = +q.masterPolicyId;
      } else if (q.policyInquiryId) {
        this.initPolicyInquiry(+q.policyInquiryId);
      }
      this.handleButtons();
    }
  }

  setInitialLimitData(readFromCache: boolean, typeId: number) {
    if (readFromCache && this.cacheService.limitRequestInCreation) {
      console.log('restore limit request', this.cacheService.limitRequestInCreation);
      this.limitRequest = this.cacheService.limitRequestInCreation;
      if (!this.limitRequest.status) {
        // let angular initialize view
        setTimeout(() => this.loadLimit(), 0);
      } else {
        this.limit = <LimitDto>{};
      }
    } else {
      console.log('new limit request');
      this.limitRequest = <LimitRequestDto>{limit: {}, type: {id: typeId}};
      this.cacheService.limitRequestInCreation = this.limitRequest;
      console.log('store limit request', this.cacheService.limitRequestInCreation);
      this.limitDecision = undefined;
      this.limit = undefined;
    }
    this.limitDecision = undefined;
    console.log('store limit request', this.cacheService.limitRequestInCreation);
  }

  onSave() {
    this.clearErrors();
    if (!this.limitRequest.limit.id && !this.selectors.allSet) {
      this.showFormError();
      return;
    }
    if (!this.limitDecision) {
      this.saveLimitRequestWithWarningPopup();
    } else {
      this.saveLimitDecisionWithWarningPopup();
    }
  }

  saveLimitRequestWithWarningPopup() {
    if (!this.requestComponent.form.valid) {
      this.showFormError();
      StringUtils.logFormInvalidFieldsRecursive(this.requestComponent.form);
      return;
    }
    const showCancelPreviousRequestPopup =
      this.previousRequest &&
      ![LimitRequestStatus.DONE, LimitRequestStatus.CANCELLED].includes(this.previousRequest.status.id);
    if (showCancelPreviousRequestPopup) {
      this.cancelRequestPopup(this.previousRequest, this.saveLimitRequest.bind(this));
    } else {
      this.saveLimitRequest();
    }
  }

  saveLimitDecisionWithWarningPopup() {
    if (!this.decisionComponent.form.valid) {
      console.log('decision form', this.decisionComponent.form);
      this.showFormError();
      StringUtils.logFormInvalidFieldsRecursive(this.decisionComponent.form);
      return;
    }
    if (this.limitDecision.type.id === LimitDecisionType.CANCELLATION) {
      this.cancelDecisionPopup(this.saveLimitDecision.bind(this));
    } else {
      this.saveLimitDecision();
    }
  }

  saveLimitRequest() {
    console.log('saving limit request..');
    this.requestComponent.closeSelectors();
    this.inProgress = true;
    this.limitService.saveLimitRequest(this.limitRequest).subscribe({
      next: (req) => {
        this.limitRequest = req;
        this.clearErrors();
        console.log('limit request saved:', req);
        this.showSavedMsg();
        this.limitGuiService.justCreatedLimitRequest = req;
        this.router.toLimitDetailsWithParams({requestId: req.id, readFromCache: true});
      },
      error: (error) => {
        this.handleServerError(error);
      },
    });
  }

  saveLimitDecision() {
    this.afterLimitDecisionSaved(this.limitService.saveLimitDecision(this.limitDecision));
  }

  afterLimitDecisionSaved(savedDecisionObs: Observable<LimitDecisionDto>) {
    this.inProgress = true;
    const oldDecisionTypes = [...this.decisionTypes];
    this.decisionTypes = []; // hide decision button until types are refreshed
    savedDecisionObs.subscribe({
      next: (limitDecision) => {
        this.limitDecision = limitDecision;
        console.log('limit decision saved:', limitDecision);
        this.handleButtons();
        this.getLimitRequest(this.limitDecision.limitRequest.id, false, null, limitDecision.warnings);
        this.updateErrors(limitDecision.warnings);
        this.showSavedMsg();
      },
      error: (error) => {
        this.checkUserQuotaExceededError(error);
        this.handleServerError(error);
        this.decisionTypes = oldDecisionTypes;
      },
    });
  }

  checkUserQuotaExceededError(error: BackendError) {
    if (this.isSpecificError(error, ErrorCode.USER_QUOTA_EXCEEDED)) {
      if (!this.limitDecision.acceptanceTask) {
        this.limitDecision.acceptanceTask = <TaskWithUserDto>{};
      }
    }
  }

  handleLimitLoadError(error: BackendError) {
    this.handleServerError(error);
  }

  get previousRequest() {
    return this.limit.requests && this.limit.requests.length > 0
      ? this.limit.requests[this.limit.requests.length - 1]
      : undefined;
  }

  initClient(clientId: number) {
    if (!this.portal && clientId > 0) {
      this.companyService.getCompanySimple(clientId).subscribe((c) => {
        this.limitRequest.limit.insured = c;
        this.loadLimit();
      });
    }
  }

  initBuyer(clientId: number) {
    if (clientId > 0) {
      this.companyService.getCompanySimple(clientId).subscribe((c) => {
        this.limitRequest.limit.buyer = <ThirdPartyBaseDto>{company: c};
        this.loadLimit();
      });
    }
  }

  initPolicyInquiry(inquiryId: number) {
    const criteria = BusinessUtils.createPolicyInquiryVersionSearchCriteria();
    criteria.criteria.policyInquiry.id = inquiryId;
    criteria.criteria.last = true;
    this.inquiryService.searchByCriteria(criteria).subscribe({
      next: (res) => {
        const inqV = <PolicyInquiryVersionSimpleDto>res.result[0];
        this.limitRequest.limit.policyLimitList = BusinessUtils.initialPolicyListFromPolicyInquiryVersion(inqV);
        this.selectors.policyListChanged(this.limitRequest.limit.policyLimitList);
      },
      error: (error) => this.handleServerError(error),
    });
  }

  loadLimit() {
    if (!this.selectors.allSet) {
      return;
    }
    console.log('onCreateInitialRequest::', this.limitRequest.type);
    this.clearErrors();
    this.limitService.existingOrInitialRequest(this.limitRequest).subscribe({
      next: (req) => {
        console.log('existingOrInitial::', req);
        if (req.id) {
          this.router.toLimitDetails(req.id);
        } else {
          this.limitRequest = req;
          this.cacheService.limitRequestInCreation = req;
          this.limit = <LimitDto>{};
          this.handleButtons();
        }
      },
      error: (error) => this.handleLimitLoadError(error),
    });
  }

  showConfirmationPopupForNewRequestAfterSpecial(newType: DictionaryBaseDto) {
    const msgParams = {previousType: this.limitRequest.type.name, newType: newType.name};
    const actionParams = [newType.id];
    this.limitConfirmDialog.openAndExecuteOnConfirm(
      'limitDetails.popup.newRequestAfterSpecial.title',
      'limitDetails.popup.newRequestAfterSpecial.msg',
      this.createNewRequestAfterSpecial.bind(this),
      this.onCancel.bind(this),
      msgParams,
      actionParams
    );
  }

  onCreateNewRequest(type: DictionaryBaseDto) {
    this.clearErrors();
    console.log('onCreateNewRequest:: id =' + this.limitRequest.limit.id);
    if (
      this.limitDecision &&
      this.limitDecision.businessStatus.id === LimitDecisionStatus.ACTIVE &&
      this.limitRequest.type.id !== LimitRequestType.STANDARD &&
      this.isPositive(this.limitDecision)
    ) {
      this.showConfirmationPopupForNewRequestAfterSpecial(type);
    } else {
      this.createNewRequest(type);
    }
  }

  isPositive(decision: LimitDecisionDto) {
    return [LimitDecisionType.APPROVAL, LimitDecisionType.PARTIAL_APPROVAL, LimitDecisionType.REDUCTION].includes(
      decision.type.id
    );
  }

  createNewRequestAfterSpecial(params: number[]) {
    this.createNewRequest(<DictionaryBaseDto>{id: params[0]});
  }

  createNewRequest(type: DictionaryBaseDto) {
    this.limitService.getNewLimitRequest(this.limitRequest.limit.id, type.id).subscribe({
      next: (limit) => {
        this.limitDecision = null;
        this.cd.detach();
        this.setLimitRequest(limit);
        this.cd.reattach();
      },
      error: (error) => {
        this.handleServerError(error);
        this.cd.reattach();
      },
    });
  }

  onCreateNewDecision(decisionType: DictionaryDto) {
    this.clearErrors();
    console.log('detype', decisionType);
    this.limitService.getNewLimitDecision(this.limitRequest.id, decisionType.id).subscribe({
      next: (decision) => {
        console.log('detypesss', decision);
        this.setLimitDecision(decision);
      },
      error: (error) => this.handleServerError(error),
    });
  }

  setLimit(limitId: number) {
    this.limitService.getLimit(limitId).subscribe({
      next: (limit) => {
        console.log('reloaded limit:', limit);
        this.limit = limit;
        this.handleButtons();
      },
      error: (error) => (this.serverErrors = error),
    });
    this.loadTemplates();
  }

  onSelectLimitRequest(limit: LimitRequestDto) {
    this.clearErrors();
    this.router.toLimitDetails(limit.id);
  }

  onSelectLimitDecision(dec: LimitDecisionDto) {
    this.clearErrors();
    this.setLimitDecision(dec);
  }

  onCancel() {
    super.onCancel(this._route);
  }

  cancelDecisionPopup(actionOnConfirm: Function) {
    this.limitDecisionCancelConfirmDialog.openAndExecuteOnConfirm(
      'limitDetails.cancelConfirmPopup.title',
      'limitDetails.cancelConfirmPopup.msg',
      actionOnConfirm.bind(this)
    );
  }

  onCancelDecision() {
    this.cancelDecisionPopup(this.cancelDecision);
  }

  openReductionDialog() {
    this.reductionDialog.openDialog();
  }

  onLimitReductionConfirm(req: LimitRequestReductionDto) {
    req.id = this.limitRequest.id;
    this.afterLimitDecisionSaved(this.limitService.reduceLimit(req));
  }

  onCancelRequest() {
    this.cancelRequestPopup(this.limitRequest, this.cancelRequest.bind(this));
  }

  cancelRequestPopup(requestToBeCancelled: LimitRequestInLimitDto, actionOnConfirm: Function) {
    const msgParams = {};
    msgParams['amount'] =
      StringUtils.formatNumber(requestToBeCancelled.amount.value) + ' ' + requestToBeCancelled.currency.code;
    msgParams['date'] = this.formatService.formatDate(requestToBeCancelled.creationDate);
    msgParams['buyer'] = this.getBuyer().company.registrationName;
    // new amount exists only when popup on new limit creation from portal
    msgParams['newAmount'] = !this.portal
      ? undefined
      : StringUtils.formatNumber(this.limitRequest.amount.value) + ' ' + this.limitRequest.currency.code;
    this.limitConfirmDialog.openAndExecuteOnConfirm(
      'limitDetails.cancelRequestPopup.title',
      this.portal ? 'limitDetails.cancelRequestPopup.msgPortal' : 'limitDetails.cancelRequestPopup.msgIntranet',
      actionOnConfirm,
      undefined,
      msgParams
    );
  }

  cancelRequest() {
    this.inProgress = true;
    this.limitService.cancelLimitRequest(this.limitRequest.id).subscribe({
      next: (req) => {
        this.limitRequest = req;
        console.log('limit request cancelled:', req);
        this.showSavedMsg();
        this.setLimit(req.limit.id);
        this.setDecisionFromRequest();
        this.handleButtons();
        this.inProgress = false;
      },
      error: (error) => this.handleServerError(error),
    });
  }

  onProlongDecision() {
    this.limitService.getLimitDecisionProlongation(this.limitRequest.id).subscribe({
      next: (dec) => {
        console.log('got decision prolongation:', dec);
        this.setLimitDecision(dec);
      },
      error: (error) => (this.serverErrors = error),
    });
  }

  cancelDecision() {
    this.afterLimitDecisionSaved(this.limitService.cancelLimitDecision(this.limitRequest.id));
  }

  refreshDecisionTypes(requestId: number) {
    if (requestId && requestId > 0) {
      this.limitService.getPossibleDecisionTypes(requestId).subscribe((types) => {
        this.decisionTypes = types;
        this.handleButtons();
      });
    } else {
      this.decisionTypes = [];
    }
    console.log('decision types:', this.decisionTypes);
  }

  get callerCompany() {
    return this.loggedUserService.getLoggedUserData().company;
  }

  canCreateNextRequestOrDecision() {
    return (
      !this.portal ||
      (this.limitRequest.limit.insured &&
        (this.limitRequest.limit.insured.id === this.callerCompany.id ||
          (this.limit.policyLimitList.activeVersion && this.limit.policyLimitList.activeVersion.callerCoOwner)))
    );
  }

  handleButtons() {
    // buttons are displayed with delay so they appear at once all together
    setTimeout(() => {
      this.handleButtonsInternal();
    }, 200);
  }

  handleButtonsInternal() {
    const readMode = !this.limit || !this.limit.businessStatus || this.limit.businessStatus.id !== LimitStatus.ACTIVE;
    const requestEditionMode = !this.limitDecision && this.limitRequest && !this.limitRequest.id;
    const decisionEditionMode =
      !!this.limitDecision &&
      (!this.limitDecision.status ||
        (this.limitDecision.status.id === LimitDecisionStatus.SENT_TO_APPROVAL &&
          this.limitDecision.businessStatus.id !== LimitDecisionStatus.INACTIVE));
    console.log(
      'handleButtons: requestEditionMode = ' + requestEditionMode + ', decisionEditionMode =' + decisionEditionMode
    );
    const isLastRequest = this.limitRequest && this.limitRequest.last;
    const isFDLimit = false;
    this.newRequestButton.hidden =
      readMode ||
      !this.limit ||
      !this.limit.requests ||
      requestEditionMode ||
      decisionEditionMode ||
      (isFDLimit &&
        this.lastDecision &&
        this.lastDecision.businessStatus.id === LimitDecisionStatus.ACTIVE &&
        this.lastDecision.amount.value > 0) ||
      !this.canCreateNextRequestOrDecision();
    this.newRequestButton.disabled =
      this.inProgress ||
      !this.limitRequest.last ||
      [LimitRequestStatus.UNRESOLVED_COMPANY].includes(this.limitRequest.status.id);
    this.saveButton.hidden = !this.limit || (!requestEditionMode && !decisionEditionMode);
    this.cancelButton.hidden = !requestEditionMode && !decisionEditionMode;
    this.cancelDecisionButton.hidden =
      readMode ||
      !this.portal ||
      requestEditionMode ||
      decisionEditionMode ||
      isFDLimit ||
      !this.limitDecision ||
      !this.limitDecision.last ||
      !this.limitRequest.last ||
      this.limitDecision.type.id === LimitDecisionType.CANCELLATION ||
      this.limitDecision.type.id === LimitDecisionType.REFUSAL ||
      this.limitDecision.businessStatus.id !== LimitDecisionStatus.ACTIVE ||
      !this.canCreateNextRequestOrDecision();
    this.newDecisionButtonHidden =
      this.inProgress ||
      readMode ||
      !this.limit ||
      this.decisionTypes.length < 1 ||
      decisionEditionMode ||
      requestEditionMode ||
      !this.limitRequest.lastReadyForDecision ||
      !this.canCreateNextRequestOrDecision();
    this.prolongDecisionButton.hidden =
      readMode ||
      this.newDecisionButtonHidden ||
      !this.limitDecision ||
      !this.limitDecision.last ||
      [LimitDecisionType.CANCELLATION, LimitDecisionType.RISK_WITHDRAWAL, LimitDecisionType.REFUSAL].includes(
        this.limitDecision.type.id
      ) ||
      !this.canCreateNextRequestOrDecision();
    this.cancelRequestButton.hidden =
      readMode ||
      !isLastRequest ||
      (this.limitDecision && !this.limitDecision.id) ||
      requestEditionMode ||
      [LimitRequestStatus.CANCELLED, LimitRequestStatus.DONE].includes(this.limitRequest.status.id) ||
      !this.canCreateNextRequestOrDecision();
    this.limitReductionButton.hidden = readMode || !this.limitDecision || !this.limitDecision.reducibleFromPortal;
  }

  private setDecisionFromRequest(decisionId?: number) {
    if (this.limitRequest.decisions && this.limitRequest.decisions.length > 0) {
      const decision = decisionId
        ? this.limitRequest.decisions.find((d) => d.id === decisionId)
        : this.limitRequest.decisions[this.limitRequest.decisions.length - 1];
      this.setLimitDecision(decision);
    } else {
      this.limitDecision = null;
    }
  }

  private setLimitDecision(decision: LimitDecisionDto) {
    this.limitDecision = decision;
    // this.loadTemplates();
    this.handleButtons();
  }

  loadTemplates() {
    if (this.portal || !this.limitRequest.limit.policyLimitList.masterPolicyContract) {
      return;
    }
    this.policyContractVersionService
      .getPolicyContractVersion(this.limitRequest.limit.policyLimitList.masterPolicyContract.lastVersion.id)
      .subscribe((policyContractVersion) => {
        const langId = (policyContractVersion.language && policyContractVersion.language.id) || null;
        this.templateService
          .findByType(DocumentType.LIMIT, BusinessObjectType.LIMIT_DECISION, langId)
          .subscribe((result) => (this.templates = result));
      });
  }

  private setLimitRequest(limit: LimitRequestDto, decisionId?: number) {
    this.limitRequest = limit;
    console.log('Set limit request: ', limit);
    this.objectNotFound = false;
    this.setDecisionFromRequest(decisionId);
    this.setLimit(limit.limit.id);
    this.handleButtons();
    this.decisions = limit.decisions.reverse();
  }

  private getLimitRequest(
    requestId: number,
    readRequestFromCache = false,
    decisionId?: number,
    warningsFromDecision?: ErrorReason[]
  ) {
    this.clearErrors();
    this.relatedObjectsEditable = false;
    if (
      readRequestFromCache &&
      this.limitGuiService.justCreatedLimitRequest &&
      this.limitGuiService.justCreatedLimitRequest.id === requestId
    ) {
      this.setLimitRequest(this.limitGuiService.justCreatedLimitRequest);
      this.updateErrors(this.limitGuiService.justCreatedLimitRequest.warnings);
      // clear cache
      this.limitGuiService.justCreatedLimitRequest = undefined;
      this.refreshRequestAndDecisionTypes(requestId);
    } else {
      this.limitService.getLimitRequest(requestId).subscribe(
        (req) => {
          console.log('got limit request:', req);
          this.setLimitRequest(req, decisionId);
          this.updateErrors(warningsFromDecision ? warningsFromDecision : req.warnings);
          this.refreshRequestAndDecisionTypes(requestId);
        },
        (error) => this.handleServerError(error)
      );
    }
  }

  refreshRequestAndDecisionTypes(requestId: number) {
    this.refreshRequestTypes();
    this.refreshDecisionTypes(requestId);
  }

  refreshRequestTypes() {
    this.requestTypes = this.allRequestTypes;
  }

  getBuyer() {
    return BusinessUtils.getThirdPartyCompany(this.limitRequest.limit.buyer);
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }

  get lastDecision(): LimitDecisionBaseDto {
    return !this.limit ? undefined : this.limit.lastDecision;
  }

  get requestInitialized() {
    // when new limit is created, at the beginning limitRequest.status is not set and only the top selectors are visible
    return this.limitRequest && this.limitRequest.status;
  }
}

export class LimitViewParams {
  // string when reading
  readFromCache?: boolean;
  masterPolicyId?: number | string;
  insuredId?: number | string;
  buyerCompanyId?: number | string;
  typeId?: number | string; // request type or limit type
  policyInquiryId?: number | string;
  decisionId?: number | string;
  requestId?: number | string;
}
