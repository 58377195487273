import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {LimitListComponent} from './components/limit-list.component';
import {FilterableLimitListComponent} from './components/filterable-limit-list.component';
import {LimitGuiService} from './services/limit-gui.service';
import {LimitRequestSelectorComponent} from './components/limit-request-selector.component';
import {PolicyLimitListSharedModule} from '../../policy-limit-list/shared/policy-limit-list-shared.module';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CollapseModule,
    PolicyLimitListSharedModule,
  ],
  declarations: [LimitListComponent, FilterableLimitListComponent, LimitRequestSelectorComponent],
  exports: [LimitListComponent, FilterableLimitListComponent, LimitRequestSelectorComponent],
  providers: [LimitGuiService],
})
export class LimitSharedModule {}
