import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgForm, UntypedFormGroup} from '@angular/forms';

import {ListEmitters} from '../../../bonding_shared/components/details-view/list-emitters';
import {ContactPersonDto, LimitRequestDto} from '../../../bonding_shared/model';
import {
  Country,
  DictionaryProfile,
  InquiryProductTypes,
  LegalForm,
  LimitDecisionSource,
  LimitRequestStatus,
  PolicyContractType,
} from '../../../bonding_shared/model/dictionary-ids';
import {
  AppConfigService,
  DictionaryService,
  LoggedUserService,
  RouterService,
  SettableObjectType,
  View,
} from '../../../bonding_shared/services';
import {TranslateService} from '@ngx-translate/core';
import {CompanySelectedEvent} from '../../company/components/shared/extended-selectors/company-selector-extended.component';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';
import {StringUtils} from '../../../bonding_shared/utils/string-utils';

@Component({
  selector: 'limit-request',
  templateUrl: './limit-request.component.pug',
})
export class LimitRequestComponent extends ListEmitters implements OnInit {
  static VALUE_MAX_LENGTH = 16;

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  @Input() set limitRequest(r: LimitRequestDto) {
    this.request = r;
    this.isSingleRiskOrTurnover();
    this.baseMultiplier = this.getBaseMultiplier();
    this.amoutType = this.getAmoutType();
  }

  @Input() showErrors = false;

  form: UntypedFormGroup;
  longLabel = false;
  request: LimitRequestDto;
  currencyDictionaryProfile: DictionaryProfile;
  baseMultiplier = this.getBaseMultiplier();
  amoutType = this.getAmoutType();

  readonly GUARANTOR_SELECTOR = 'RequestGuarantor';

  readonly LimitRequestStatus = LimitRequestStatus;
  readonly LegalForm = LegalForm;
  readonly Country = Country;
  readonly StringUtils = StringUtils;
  readonly valueMaxLength = LimitRequestComponent.VALUE_MAX_LENGTH - 2; // 14 digits + 000 (backend allows only 17 digits)

  constructor(
    public dictionaryService: DictionaryService,
    public router: RouterService,
    protected translateService: TranslateService,
    public loggedUserService: LoggedUserService,
    public appService: AppConfigService
  ) {
    super();
    this.initializeSelectorEmittersByNames(true, [this.GUARANTOR_SELECTOR]);
  }

  ngOnInit() {
    this.longLabel = this.loggedUserService.longLabelLanguage();
    this.form = this.ngForm.form;
  }

  hasCollaterals() {
    return (
      this.request &&
      (this.request.bill ||
        this.request.mortgageLandRegisterNo ||
        this.request.collateralsComment ||
        this.productCollaterals.length > 0)
    );
  }

  get productCollaterals() {
    if (
      this.request.limit.policyLimitList.bondingContractInquiry &&
      this.request.limit.policyLimitList.bondingContractInquiry.collaterals
    ) {
      return this.request.limit.policyLimitList.bondingContractInquiry.collaterals;
    } else if (this.request.limit.policyLimitList.bondingContract) {
      // show contract collaterals
      return [];
    } else {
      return [];
    }
  }

  contactPersonCheckboxChange(checked: boolean) {
    if (!checked) {
      this.request.contactPerson = undefined;
    } else {
      this.request.contactPerson = <ContactPersonDto>{};
    }
  }

  addGuarantor(e: CompanySelectedEvent) {
    if (
      e.thirdParty.company &&
      e.thirdParty.company.id &&
      this.request.guarantors.filter((g) => g.company.id && g.company.id === e.thirdParty.company.id).length > 0
    ) {
      return;
    }
    this.request.guarantors.push(e.thirdParty);
  }

  isNewRequest(): boolean {
    return !this.request || !this.request.id || this.request.id === 0;
  }

  get portalMode() {
    return this.loggedUserService.portal;
  }

  createNewGuarantor() {
    this.router.toCompanyDetailsNew(View.LIMIT_DETAILS.id, SettableObjectType.LIMIT_GUARANTOR);
  }

  expandableSection() {
    // temporary
    return true;
  }

  hasAutoDecision() {
    return (
      this.request &&
      this.request.decisions &&
      this.request.decisions.length > 0 &&
      this.request.decisions[0].source &&
      this.request.decisions[0].source.id === LimitDecisionSource.AUTO_DECISION
    );
  }

  isSingleRiskOrTurnover() {
    const result =
      (this.request.limit.policyLimitList.masterPolicyContract &&
        (this.request.limit.policyLimitList.masterPolicyContract.productType.id === PolicyContractType.SINGLE_RISK ||
          this.request.limit.policyLimitList.masterPolicyContract.productType.id === PolicyContractType.TURNOVER)) ||
      (this.request.limit.policyLimitList.policyInquiry &&
        InquiryProductTypes.CREDENDO_SINGLE_RISK.includes(
          this.request.limit.policyLimitList.policyInquiry.productType.id
        ));
    if (result === undefined) {
      return false;
    } else {
      return result;
    }
  }

  getBaseMultiplier() {
    if (this.request && this.isSingleRiskOrTurnover()) {
      return 1;
    }
    return 1000;
  }

  getAmoutType() {
    if (this.request && this.isSingleRiskOrTurnover()) {
      return 'decimal';
    }
    return 'integer';
  }

  selectLabelForValue() {
    if (this.isSingleRiskOrTurnover()) {
      return 'limitDetails.specialLimitAmount';
    }
    return 'limitDetails.amount';
  }

  guarantorListChanged(guarantorCount: number) {
    if (guarantorCount === 0) {
      this.request.guarantorComment = undefined;
    }
  }

  public getContactPersonTitle(): string {
    return 'limitDetails.contactPerson.title';
  }

  closeSelectors() {
    this.openSelectorEmitters[this.GUARANTOR_SELECTOR].next(false);
  }
}
