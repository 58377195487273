import {Component, OnInit} from '@angular/core';
import {PolicyContractViewService} from '../../services/policy-contract-view.service';
import {Section} from './section';
import {AppConfigService, DictionaryService} from '../../../../bonding_shared/services';
import {DictionaryBaseDto} from '../../../../bonding_shared/model';
import {PolicyTerminationType} from '../../../../bonding_shared/model/dictionary-ids';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
  selector: 'termination-section',
  templateUrl: './termination-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class TerminationSectionComponent extends Section implements OnInit {
  self = this;
  showNoticeReason = false;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    protected dictionaryService: DictionaryService
  ) {
    super(policyContractViewService, appService, undefined, dictionaryService);
  }

  ngOnInit(): void {
    this.updateShowNoticeReason(this.policy.terminationType);
  }

  onTerminationTypeChanged(terminationType: DictionaryBaseDto) {
    const p = this.policy;
    p.noticeReason = null;
    p.notificationOfTerminationDate =
      terminationType && terminationType.id === PolicyTerminationType.TERMINATED_BY_INSURED ? new Date() : null;
    p.withdrawalOnTime = this.isResignationType() ? true : null;
    this.updateShowNoticeReason(terminationType);
  }

  isResignationType(): boolean {
    const tId = this.policy && this.policy.terminationType && this.policy.terminationType.id;
    return tId === PolicyTerminationType.RESIGNATION;
  }

  onNotificationOfTerminationChanged(checked: boolean) {
    if (!checked) {
      delete this.policy.notificationOfTerminationDate;
    }
  }

  private updateShowNoticeReason(terminationType: DictionaryBaseDto) {
    if (terminationType) {
      this.dictionaryService.getDictionaryEntry('PolicyTerminationType', terminationType.id).subscribe((tt) => {
        this.showNoticeReason =
          tt.relatedDictionaries &&
          tt.relatedDictionaries['NoticeReason'] &&
          tt.relatedDictionaries['NoticeReason'].length > 0;
      });
    } else {
      this.showNoticeReason = false;
    }
  }
}
