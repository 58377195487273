import {Component, OnInit, ViewChild} from '@angular/core';
import {LimitGuiService} from './shared/services/limit-gui.service';
import {RouterService} from '../../bonding_shared/services/router-service';
import {SearchViewComponent} from '../../bonding_shared/components/search/search-view/search-view.component';
import {
  BusinessUnitBaseDto,
  DictionaryDto,
  LimitRequestCriteriaDto,
  LimitRequestDto,
  PolicyContractBaseDto,
  PolicyContractVersionCriteriaDto,
} from '../../bonding_shared/model/dtos';
import {LimitListComponent} from './shared/components/limit-list.component';
import {SearchView} from '../../bonding_shared/components/search/search-view/search-view';
import {TextSearchCriteria} from '../../bonding_shared/components/search/model/text-search-criteria';
import {BusinessUnitService} from '../../bonding_shared/services/business-unit.service';
import {LimitService} from '../../bonding_shared/services/limit.service';
import {AppConfigService, DictionaryService, GrowlService} from '../../bonding_shared/services';
import {
  LimitCategory,
  LimitDecisionStatus,
  LimitDecisionType,
  LimitElementaryRight,
  LimitRequestSource,
  LimitRequestStatus,
  LimitRequestType,
} from '../../bonding_shared/model/dictionary-ids';
import {BusinessPropertiesService} from '../../bonding_shared/services/business-properties.service';
import {DictionaryBaseDto} from '../../bonding_shared/model';

@Component({
  selector: 'limit-search',
  templateUrl: './limit-search.component.pug',
})
export class LimitSearchComponent extends SearchView implements OnInit {
  @ViewChild(SearchViewComponent, {static: true}) searchView: SearchViewComponent<
    LimitRequestCriteriaDto,
    LimitRequestDto
  >;
  @ViewChild(LimitListComponent, {static: true}) limitList: LimitListComponent;

  policySearchCriteria: PolicyContractVersionCriteriaDto = <PolicyContractVersionCriteriaDto>{};
  policyTextCriteria: TextSearchCriteria = new TextSearchCriteria();
  businessUnits: BusinessUnitBaseDto[];
  systemCurrency: DictionaryBaseDto;
  requestTypes: DictionaryBaseDto[] = [];

  readonly LimitRequestType = LimitRequestType;
  readonly LimitRequestSource = LimitRequestSource;
  readonly hiddenStatusIds = [LimitRequestStatus.INACTIVE, LimitDecisionStatus.INACTIVE];
  readonly LimitRights = LimitElementaryRight;
  readonly LimitCategory = LimitCategory;

  constructor(
    private businessUnitService: BusinessUnitService,
    private dictionaryService: DictionaryService,
    public guiService: LimitGuiService,
    public businessPropertiesService: BusinessPropertiesService,
    public limitService: LimitService,
    private growlService: GrowlService,
    public router: RouterService,
    public appService: AppConfigService
  ) {
    super();
    this.selectorNameList = ['Buyer', 'Policy', 'Insured', 'PolicyListOwner'];
    this.initializeSelectorEmitters(true);
    this.policySearchCriteria.last = true;
    this.policySearchCriteria.policyContract = <PolicyContractBaseDto>{};
    this.policyTextCriteria.last = true;
  }

  ngOnInit() {
    this.businessUnitService.getBusinessUnits().subscribe((data) => (this.businessUnits = data));
    this.setSystemCurrency();
    this.dictionaryService.getDictionary('LimitRequestType').subscribe((dicts) => this.setLimitTypes(dicts));
  }

  setLimitTypes(types: DictionaryDto[]) {
    const allowedTypes = [LimitRequestType.STANDARD];
    this.requestTypes = types.filter((t) => allowedTypes.includes(t.id));
  }

  onCreateNewRequest(typeId: number) {
    const buyerId = this.searchView.dataProvider.searchCriteria.criteria.buyerCompanyId;
    const insuredId = this.searchView.dataProvider.searchCriteria.criteria.insuredId;
    const policyId = this.searchView.dataProvider.searchCriteria.criteria.masterPolicyContractId;
    this.router.toLimitDetailsNew(insuredId, policyId, buyerId, typeId);
  }

  reductionSelected() {
    const criteria = this.guiService.searchCriteria.dataProvider.searchCriteria.criteria;
    return criteria.decisionType && criteria.decisionType.id === LimitDecisionType.REDUCTION;
  }

  export() {
    this.searchView.serverErrors = undefined;
    this.limitService.executeExportAsync(this.guiService.searchCriteria.dataProvider.searchCriteria.criteria).subscribe(
      () => this.growlService.notice('common.reportGeneration'),
      (error) => (this.searchView.serverErrors = error)
    );
  }

  get totalRequestAmount(): number {
    return this.querySum('amount');
  }

  get totalDecisionAmount(): number {
    return this.querySum('#lastDecision.amount');
  }

  querySum(field: string): number {
    if (this.searchView.resultList && this.searchView.resultList.sums) {
      return this.searchView.resultList.sums[field];
    }
    return 0;
  }

  setSystemCurrency() {
    this.businessPropertiesService.getProperties().subscribe((p) => (this.systemCurrency = p.systemCurrency));
  }

  get criteria() {
    return this.guiService.searchCriteria.dataProvider.searchCriteria.criteria;
  }

  hasDecisionChanged() {
    this.criteria.decisionType = undefined;
    this.criteria.decisionSubtype = undefined;
    this.criteria.decisionSource = undefined;
    this.criteria.decisionStatus = undefined;
  }

  requestChanged() {
    this.criteria.creationDateRange.dateFrom = undefined;
    this.criteria.creationDateRange.dateTo = undefined;
    this.criteria.type = undefined;
    this.criteria.id = undefined;
    this.criteria.source = undefined;
    this.criteria.businessStatus = undefined;
  }

  categoryChanged() {
    this.criteria.policyInquiryProductType = undefined;
    this.criteria.policyContractType = undefined;
    this.criteria.bondingContractType = undefined;
    this.criteria.bondingContractInquiryType = undefined;
  }

  get showRequest() {
    return this.guiService.searchCriteria.showRequest;
  }

  set showRequest(v: boolean) {
    this.guiService.searchCriteria.showRequest = v;
  }
}
