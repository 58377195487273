import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {CompanySearchComponent} from './company-search.component';
import {CompanyDetailsComponent} from './company-details.component';
import {CompanyPreviewComponent} from './company-preview.component';
import {CompanyInfoComponent} from './components/shared/company-info.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {CompanyGuiService} from './services/company-gui.service';
import {FilterableBondVersionListComponent} from '../bond/components/shared/filterable-bond-version-list.component';
import {EmbeddableCompanySearchComponent} from './components/shared/embeddable-company-search.component';
import {CompanySharedModule} from './components/shared/company-shared.module';
import {LimitSharedModule} from '../limit/shared/limit-shared.module';
import {CompanyRatingComponent} from './company-rating.component';
import {RepositoryDocumentListModule} from '../../bonding_shared/components/repository-document-list/repository-document-list.module';
import {PolicyContractSharedModule} from '../policy/components/shared/policy-contract-shared.module';
import {ClaimSharedModule} from '../claim/components/shared/claim-shared.module';
import {BondSharedModule} from '../bond/components/shared/bond-shared.module';
import {CompanyFinancialDataComponent} from './company-financial-data.component';
import {CompanyCRMComponent} from './company-crm.component';
import {ContactNoteSharedModule} from '../contactNote/components/shared/contact-note-shared.module';
import {InvoiceSharedModule} from '../invoice/components/shared/invoice-shared.module';
import {DocumentModule} from '../document/document.module';
import {TranslateModule} from '@ngx-translate/core';
import {ContractSharedModule} from '../bonding-contract/contract/components/shared/contract-shared.module';
import {GroupModule} from '../group/group.module';
import {BrokerContractSharedModule} from '../broker-contract/components/shared/broker-contract-shared.module';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {CompanyRatingCalculationComponent} from './company-rating-calculation.component';
import {CompanyManagersComponent} from './company-managers.component';
import {CompanyLegalInsolvencyService} from '../../bonding_shared/services/company-legal-insolvency.service';
import {CompanyExtendedSelectorModule} from './components/shared/extended-selectors/company-extended-selector.module';
import {BiReportModule} from '../bi-report/bi-report.module';
import {CompanyAccessGuard} from './_guards/company-access.guard';
import {CompanyDetailAccessGuard} from './_guards/company-detail-access.guard';
import {CompanyBISearchGuard} from './_guards/company-bi-search.guard';
import {CompanySearchGuard} from './_guards/company-search.guard';
import {CompanyRatingDetailAccessGuard} from './_guards/company-rating-detail-access.guard';
import {CompanyManagersAccessGuard} from './_guards/company-managers-access.guard';
import {CompanyFinancialDataAccessGuard} from './_guards/company-financial-data-access.guard';
// tslint:disable-next-line:max-line-length
import {BondingContractInquiryVersionListComponent} from '../bonding-contract/inquiry/components/shared/bonding-contract-inquiry-version-list.component';
import {BondingContractInquirySharedModule} from '../bonding-contract/inquiry/components/shared/bonding-contract-inquiry-shared.module';
import {CompanyExternalProvidersComponent} from './company-external-providers.component';
import {ExternalProviderSharedModule} from '../external-provider/components/shared/external-provider-shared.module';
import {BusinessObjectInfoModule} from '../business-object-info/business-object-info.module';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    LimitSharedModule,
    RepositoryDocumentListModule,
    PolicyContractSharedModule,
    ClaimSharedModule,
    BondSharedModule,
    ContactNoteSharedModule,
    BsDropdownModule,
    PaginationModule,
    InvoiceSharedModule,
    DocumentModule,
    BiReportModule,
    TranslateModule,
    ContractSharedModule,
    GroupModule,
    BrokerContractSharedModule,
    TabsModule.forRoot(),
    CompanyExtendedSelectorModule,
    BondingContractInquirySharedModule,
    ExternalProviderSharedModule,
    BusinessObjectInfoModule,
  ],
  declarations: [
    CompanySearchComponent,
    CompanyDetailsComponent,
    CompanyPreviewComponent,
    CompanyManagersComponent,
    CompanyRatingComponent,
    CompanyRatingCalculationComponent,
    CompanyFinancialDataComponent,
    CompanyCRMComponent,
    CompanyExternalProvidersComponent,
  ],
  exports: [
    CompanyInfoComponent,
    FilterableBondVersionListComponent,
    BondingContractInquiryVersionListComponent,
    EmbeddableCompanySearchComponent,
  ],
  providers: [
    CompanyGuiService,
    CompanyLegalInsolvencyService,
    CompanyAccessGuard,
    CompanyBISearchGuard,
    CompanyDetailAccessGuard,
    CompanySearchGuard,
    CompanyRatingDetailAccessGuard,
    CompanyManagersAccessGuard,
    CompanyFinancialDataAccessGuard,
  ],
})
export class CompanyModule {}
