import {Injectable} from '@angular/core';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Observable} from 'rxjs';
import {
  DictionaryBaseDto,
  IdDto,
  PolicyCreationDataDto,
  PolicyCreationInfoDto,
  PolicyInquiryBaseDto,
  PolicyInquiryOfferDto,
  PolicyInquiryOfferEnvelopeDto,
  PolicyInquiryVersionCriteriaDto,
  PolicyInquiryVersionDto,
  PolicyInquiryVersionSimpleDto,
  SearchCriteria,
  StateTransitionDto,
  VoteDto,
} from '../model';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {InquiryAbstractService} from './inquiry-abstract.service';
import {TemplateService} from './template.service';
import {DictionaryBaseService} from './dictionary-base.service';

@Injectable()
export class InquiryService extends InquiryAbstractService {
  constructor(
    http: HttpClient,
    appConfigService: AppConfigService,
    loggedUserService: LoggedUserService,
    protected templateService: TemplateService,
    protected dictionaryBaseService: DictionaryBaseService
  ) {
    super(http, appConfigService, loggedUserService, templateService, dictionaryBaseService);
    this.url = this.urlPrefix + this.portalPrefix + 'policyInquiryVersion';
  }

  voteOffer(offerId: number, vote: VoteDto) {
    return this.post1<VoteDto, PolicyInquiryOfferDto>(vote, this.url + '/' + offerId + '/doVote');
  }

  closeVoting(offerId: number): Observable<PolicyInquiryOfferDto> {
    return this.postEmpty<PolicyInquiryOfferDto>(this.url + '/offer/' + offerId + '/closevoting');
  }

  getTransitions(inquiry: PolicyInquiryVersionDto): Observable<Array<StateTransitionDto>> {
    return this.get<Array<StateTransitionDto>>(this.url + '/transition/' + inquiry.status.id);
  }

  getOffer(id: number): Observable<PolicyInquiryOfferEnvelopeDto> {
    return this.get<PolicyInquiryOfferEnvelopeDto>(this.url + '/offer/' + id);
  }

  initOffer(inquiryId: number, offerTypeId: number, sourceOfferId?: number, blogOffer?: boolean, edge?: boolean) {
    const sourceOfferIdParam = sourceOfferId ? '/' + sourceOfferId : '';
    const blogOfferParam = sourceOfferId ? (blogOffer ? '/true' : '/false') : '';
    let edgeParams = '';
    if (edge === true || edge === false) {
      edgeParams = '?edge=' + edge;
    }
    return this.get<PolicyInquiryOfferEnvelopeDto>(
      this.url + '/initoffer/' + inquiryId + '/' + offerTypeId + sourceOfferIdParam + blogOfferParam + edgeParams
    );
  }

  createPolicyFromOffer(offer: PolicyCreationDataDto): Observable<PolicyCreationInfoDto> {
    return this.post1<PolicyCreationDataDto, PolicyCreationInfoDto>(offer, this.url + '/policyFromOffer');
  }

  createRenewalInquiry(policyContractVersionId: number): Observable<number> {
    return this.post<IdDto>({id: policyContractVersionId}, this.url + '/renewalInquiry').pipe(map((idDto) => idDto.id));
  }

  getLastVersion(id: number): Observable<PolicyInquiryVersionSimpleDto> {
    const crit = <SearchCriteria<PolicyInquiryVersionCriteriaDto>>{criteria: {last: true, policyInquiry: {id: id}}};
    return this.searchByCriteria<PolicyInquiryVersionCriteriaDto, PolicyInquiryVersionSimpleDto>(crit).pipe(
      map((res) => res.result[0])
    );
  }

  getClientProducts(): Observable<DictionaryBaseDto[]> {
    return this.get<DictionaryBaseDto[]>(this.url + '/clientProducts');
  }

  getParentById(id: number): Observable<PolicyInquiryBaseDto> {
    return this.get<PolicyInquiryBaseDto>(this.url + '/parent/' + id);
  }
}
