import {
  AddressDto,
  BIReportCriteriaDto,
  BIReportSimpleDto,
  BondingContractInquiryBaseDto,
  BondingContractInquiryVersionBaseDto,
  BondingContractInquiryVersionCriteriaDto,
  BondingContractInquiryVersionSimpleDto,
  BondingContractOfferCriteriaDto,
  BondingContractOfferSimpleDto,
  BusinessUnitIdDto,
  CessionBaseDto,
  CessionVersionDto,
  ClientCompanyBaseDto,
  ClientCompanyDto,
  CompanyBaseDto,
  CompanyCriteriaDto,
  CompanyDto,
  CompanyIdDto,
  CompanyIdentifierDto,
  CompanyPortalDto,
  CompanyRawDto,
  CompanySimpleDto,
  ContactDto,
  ContractLinkDto,
  ContractVersionBaseDto,
  FileImportCriteriaDto,
  FileImportSimpleDto,
  IdDto,
  LegalEventDto,
  LimitBaseDto,
  LimitRequestCriteriaDto,
  LimitRequestDto,
  LimitRequestSimpleDto,
  LocalDateTimeRange,
  PhysicalPersonDto,
  PolicyClauseTypeCriteriaDto,
  PolicyContractBaseDto,
  PolicyContractVersionSimpleDto,
  PolicyInquiryBaseDto,
  PolicyInquiryVersionBaseDto,
  PolicyInquiryVersionCriteriaDto,
  PolicyInquiryVersionSimpleDto,
  ProductLimitListBaseDto,
  ProductLimitListForLimitDto,
  ProductLimitListVersionCriteriaDto,
  ProductLimitListVersionSimpleDto,
  SearchCriteria,
  ThirdPartyBaseDto,
  ThirdPartyCriteriaDto,
} from '../model/dtos';
import {
  AddressType,
  BondStatus,
  BusinessObjectType,
  CompanyIdentifierType,
  CompanyType,
  ContactType,
  ContractStatus,
  ContractType,
  Country,
  LegalForm,
  LimitCategory,
} from '../model/dictionary-ids';
import {SearchDataProvider} from '../services/search-data-provider';
import {
  BrokerContractRelationSimpleDto,
  BrokerContractRelationVersionCriteriaDto,
  BrokerContractRelationVersionDto,
  BrokerContractSimpleDto,
  BrokerContractVersionCriteriaDto,
  BrokerContractVersionSimpleDto,
  BusinessObjectDto,
  CessionVersionCriteriaDto,
  ContractBaseDto,
  ContractVersionCriteriaDto,
  ContractVersionSimpleDto,
  DictionaryBaseDto,
  DictionaryDto,
  LocalDateRange,
  MasterPolicyContractBaseDto,
  PolicyContractVersionCriteriaDto,
  TreatyBaseDto,
  TreatyVersionCriteriaDto,
  TreatyVersionSimpleDto,
} from '../model';
import {AbstractService, LoggedUserService} from '../services';
import {DictionaryUtils} from './dictionary-utils';
import {StringUtils} from './string-utils';

export class BusinessUtils {
  static readonly BOND_STATUS_REQUEST = [
    BondStatus.BOND_REQUEST,
    BondStatus.BOND_REQUEST_ACCEPTED,
    BondStatus.BOND_REQUEST_REJECTED,
    BondStatus.BOND_REQUEST_CANCELLED,
  ];

  static isRequest(bondStatusId): boolean {
    return BusinessUtils.BOND_STATUS_REQUEST.includes(bondStatusId);
  }

  static contractNumber(contractVersion: ContractVersionBaseDto): string {
    if (!contractVersion || !contractVersion.contract) {
      return '';
    }
    const number = BusinessUtils.isContractIssued(contractVersion)
      ? contractVersion.contract.number
      : contractVersion.contract.requestNumber;
    return number + '/' + contractVersion.versionNumber;
  }

  private static isContractIssued(contractVersion: ContractVersionBaseDto): boolean {
    return BusinessUtils.isContractIssuedStatus(contractVersion.status);
  }

  public static isContractIssuedStatus(status: DictionaryBaseDto) {
    return (
      status && status.id !== ContractStatus.CONTRACT_PROSPECT && status.id >= ContractStatus.CONTRACT_NOT_ACTIVATED
    );
  }

  static createContractVersionDataProvider(
    service: AbstractService
  ): SearchDataProvider<ContractVersionCriteriaDto, ContractVersionSimpleDto> {
    const dp = BusinessUtils.createDataProvider<ContractVersionCriteriaDto, ContractVersionSimpleDto>(service);
    dp.searchCriteria.criteria.contract = <ContractBaseDto>{};
    return dp;
  }

  static createThirdPartyDataProvider(
    service: AbstractService
  ): SearchDataProvider<ThirdPartyCriteriaDto, ThirdPartyBaseDto> {
    const dp = BusinessUtils.createDataProvider<ThirdPartyCriteriaDto, ThirdPartyBaseDto>(service);
    dp.searchCriteria.criteria.company = <CompanySimpleDto>{};
    dp.searchCriteria.criteria.company.address = <AddressDto>{};
    dp.searchCriteria.criteria.lastCompanyRaw = <CompanyRawDto>{};
    return dp;
  }

  static createBondingContractInquiryVersionDataProvider(
    service: AbstractService,
    loggedUserService?: LoggedUserService
  ): SearchDataProvider<BondingContractInquiryVersionCriteriaDto, BondingContractInquiryVersionSimpleDto> {
    const dp = BusinessUtils.createDataProvider<
      BondingContractInquiryVersionCriteriaDto,
      BondingContractInquiryVersionSimpleDto
    >(service);
    dp.searchCriteria.criteria.inquiry = <BondingContractInquiryBaseDto>{};
    dp.searchCriteria.criteria.inquiry.client = <ThirdPartyBaseDto>{};
    dp.searchCriteria.criteria.inquiry.client.company = <CompanySimpleDto>{};
    dp.searchCriteria.criteria.creationDateRange = <LocalDateRange>{};
    dp.searchCriteria.criteria.businessUnit = <BusinessUnitIdDto>{};
    if (loggedUserService?.getLoggedUserData().businessUnit?.id) {
      dp.searchCriteria.criteria.businessUnit.id = loggedUserService.getLoggedUserData().businessUnit.id;
    }
    return dp;
  }

  static createBondingContractOfferDataProvider(
    service: AbstractService
  ): SearchDataProvider<BondingContractOfferCriteriaDto, BondingContractOfferSimpleDto> {
    const dp = BusinessUtils.createDataProvider<BondingContractOfferCriteriaDto, BondingContractOfferSimpleDto>(
      service
    );
    dp.searchCriteria.criteria.inquiryVersion = <BondingContractInquiryVersionBaseDto>{};
    return dp;
  }

  static createClientCompanyDataProvider(
    service: AbstractService
  ): SearchDataProvider<ClientCompanyBaseDto, ClientCompanyDto> {
    const dp = BusinessUtils.createDataProvider<ClientCompanyBaseDto, ClientCompanyDto>(service);
    dp.searchCriteria.criteria.client = <CompanySimpleDto>{};
    dp.searchCriteria.criteria.company = <CompanySimpleDto>{};
    return dp;
  }

  static createLimitRequestDataProvider(
    service: AbstractService
  ): SearchDataProvider<LimitRequestCriteriaDto, LimitRequestSimpleDto> {
    const dp = BusinessUtils.createDataProvider<LimitRequestCriteriaDto, LimitRequestSimpleDto>(service);
    dp.searchCriteria = BusinessUtils.createLimitRequestSearchCriteria();
    return dp;
  }

  static createPolicyLimitListDataProvider(
    service: AbstractService
  ): SearchDataProvider<ProductLimitListVersionCriteriaDto, ProductLimitListVersionSimpleDto> {
    const dp = BusinessUtils.createDataProvider<ProductLimitListVersionCriteriaDto, ProductLimitListVersionSimpleDto>(
      service
    );
    dp.searchCriteria = BusinessUtils.createPolicyLimitListSearchCriteria();
    return dp;
  }

  static createPolicyLimitListSearchCriteria(): SearchCriteria<ProductLimitListVersionCriteriaDto> {
    const sc = BusinessUtils.createSearchCriteria<ProductLimitListVersionCriteriaDto>();
    sc.criteria.subinsured = <CompanySimpleDto>{};
    sc.criteria.limitList = <ProductLimitListBaseDto>{};
    sc.criteria.limitList.masterPolicyContract = <MasterPolicyContractBaseDto>{};
    sc.criteria.limitList.factorer = <ThirdPartyBaseDto>{};
    sc.criteria.status = <DictionaryBaseDto>{};
    sc.criteria.creationDateRange = <LocalDateTimeRange>{};
    return sc;
  }

  static initialPolicyListFromPolicyInquiryVersion(inqV: PolicyInquiryVersionSimpleDto): ProductLimitListForLimitDto {
    return <ProductLimitListForLimitDto>{
      policyInquiry: {
        id: inqV.policyInquiry.id,
        client: inqV.client.company,
        number: inqV.policyInquiry.number,
        activatedOrLastVersion: BusinessUtils.toPolicyInquiryVersionBaseDto(inqV),
      },
    };
  }

  static createLimitRequestSearchCriteria(): SearchCriteria<LimitRequestCriteriaDto> {
    const sc = BusinessUtils.createSearchCriteria<LimitRequestCriteriaDto>();
    sc.criteria.limit = <LimitBaseDto>{};
    sc.criteria.last = true;
    return sc;
  }

  static createBrokerContractVersionDataProvider(
    service: AbstractService
  ): SearchDataProvider<BrokerContractVersionCriteriaDto, BrokerContractVersionSimpleDto> {
    const dp = BusinessUtils.createDataProvider<BrokerContractVersionCriteriaDto, BrokerContractVersionSimpleDto>(
      service
    );
    dp.searchCriteria.criteria.brokerContract = <BrokerContractSimpleDto>{};
    dp.searchCriteria.criteria.company = <CompanyCriteriaDto>{};
    return dp;
  }

  static createPolicyInquiryVersionSearchCriteria(): SearchCriteria<PolicyInquiryVersionCriteriaDto> {
    const sc = BusinessUtils.createSearchCriteria<PolicyInquiryVersionCriteriaDto>();
    sc.criteria.policyInquiry = <PolicyInquiryBaseDto>{};
    sc.criteria.client = <CompanyCriteriaDto>{};
    return sc;
  }

  static createPolicyVersionSearchCriteria(): SearchCriteria<PolicyContractVersionCriteriaDto> {
    const sc = BusinessUtils.createSearchCriteria<PolicyContractVersionCriteriaDto>();
    sc.criteria.policyContract = <PolicyContractBaseDto>{};
    sc.criteria.policyContract.masterPolicyContract = <MasterPolicyContractBaseDto>{};
    sc.criteria.status = <DictionaryBaseDto>{};
    return sc;
  }

  static createPolicyInquiryVersionDataProvider(
    service: AbstractService
  ): SearchDataProvider<PolicyInquiryVersionCriteriaDto, PolicyInquiryVersionSimpleDto> {
    const sc = BusinessUtils.createPolicyInquiryVersionSearchCriteria();
    return BusinessUtils.createDataProviderWithCriteria<PolicyInquiryVersionCriteriaDto, PolicyInquiryVersionSimpleDto>(
      service,
      sc
    );
  }

  static createPolicyVersionDataProvider(
    service: AbstractService
  ): SearchDataProvider<PolicyContractVersionCriteriaDto, PolicyContractVersionSimpleDto> {
    const sc = BusinessUtils.createPolicyVersionSearchCriteria();
    return BusinessUtils.createDataProviderWithCriteria<
      PolicyContractVersionCriteriaDto,
      PolicyContractVersionSimpleDto
    >(service, sc);
  }

  static createBrokerRelationDataProvider(
    service: AbstractService
  ): SearchDataProvider<BrokerContractRelationVersionCriteriaDto, BrokerContractRelationVersionDto> {
    const dp = BusinessUtils.createDataProvider<
      BrokerContractRelationVersionCriteriaDto,
      BrokerContractRelationVersionDto
    >(service);
    dp.searchCriteria.criteria.brokerRelation = <BrokerContractRelationSimpleDto>{};
    dp.searchCriteria.criteria.brokerRelation.brokerContract = <BrokerContractSimpleDto>{};
    dp.searchCriteria.criteria.brokerRelation.businessObject = <BusinessObjectDto>{};
    dp.searchCriteria.criteria.brokerRelation.businessObject.relatedTo = <DictionaryBaseDto>{};
    return dp;
  }

  static createTreatyVersionDataProvider(
    service: AbstractService
  ): SearchDataProvider<TreatyVersionCriteriaDto, TreatyVersionSimpleDto> {
    const dp = BusinessUtils.createDataProvider<TreatyVersionCriteriaDto, TreatyVersionSimpleDto>(service);
    dp.searchCriteria.criteria.treaty = <TreatyBaseDto>{};
    return dp;
  }

  static createPolicyCessionDataProvider(
    service: AbstractService
  ): SearchDataProvider<CessionVersionCriteriaDto, CessionVersionDto> {
    const dataProvider = BusinessUtils.createDataProvider<CessionVersionCriteriaDto, CessionVersionDto>(service);
    dataProvider.searchCriteria.criteria = <CessionVersionCriteriaDto>{};
    dataProvider.searchCriteria.criteria.cession = <CessionBaseDto>{};
    dataProvider.searchCriteria.criteria.cession.type = <DictionaryBaseDto>{};
    dataProvider.searchCriteria.criteria.cession.assignee = <CompanyBaseDto>{};
    dataProvider.searchCriteria.criteria.cession.masterPolicyContract = <MasterPolicyContractBaseDto>{};
    dataProvider.searchCriteria.criteria.iban = '';
    dataProvider.searchCriteria.criteria.bic = '';
    dataProvider.searchCriteria.criteria.cessionValidFromRange = <LocalDateRange>{};
    dataProvider.searchCriteria.criteria.cessionValidToRange = <LocalDateRange>{};
    dataProvider.textSearch = false;
    return dataProvider;
  }

  static createPolicyClauseTypeSearchCriteria(): SearchCriteria<PolicyClauseTypeCriteriaDto> {
    const sc = BusinessUtils.createSearchCriteria<PolicyClauseTypeCriteriaDto>();
    return sc;
  }

  static createFileImportDataProvider(
    service: AbstractService
  ): SearchDataProvider<FileImportCriteriaDto, FileImportSimpleDto> {
    const dataProvider = BusinessUtils.createDataProvider<FileImportCriteriaDto, FileImportSimpleDto>(service);
    dataProvider.textSearch = false;
    return dataProvider;
  }

  static createBiReportDataProvider(
    service: AbstractService
  ): SearchDataProvider<BIReportCriteriaDto, BIReportSimpleDto> {
    const dataProvider = BusinessUtils.createDataProvider<BIReportCriteriaDto, BIReportSimpleDto>(service);
    dataProvider.searchCriteria.criteria.company = <CompanyIdDto>{};
    dataProvider.searchCriteria.criteria.companyRaw = <CompanyIdDto>{};
    dataProvider.searchCriteria.criteria.thirdParty = <IdDto>{};
    dataProvider.searchCriteria.criteria.orderTimeRange = <LocalDateRange>{};
    return dataProvider;
  }

  static createDataProvider<C, R>(service: AbstractService): SearchDataProvider<C, R> {
    const dp = new SearchDataProvider<C, R>(service);
    dp.searchCriteria = BusinessUtils.createSearchCriteria<C>();
    return dp;
  }

  static createDataProviderWithCriteria<C, R>(
    service: AbstractService,
    sc: SearchCriteria<C>
  ): SearchDataProvider<C, R> {
    const dp = new SearchDataProvider<C, R>(service);
    dp.searchCriteria = sc;
    return dp;
  }

  static createSearchCriteria<C>(): SearchCriteria<C> {
    const sc = <SearchCriteria<C>>{};
    sc.criteria = <C>{};
    return sc;
  }

  // set parent ids based on child entries (related dictionaries)
  static setParentInDictEntries(entries: DictionaryDto[], potentialParents: DictionaryDto[], dictName: string) {
    entries.forEach((r) => {
      const parentId = BusinessUtils.getDictParentId(r.id, potentialParents, dictName);
      console.log('getParent: r.id = ' + r.id + ', ', parentId);
      r.parentDictionary = parentId ? <DictionaryBaseDto>{id: parentId} : undefined;
    });
  }

  private static getDictParentId(dictId: number, entries: DictionaryDto[], dictName: string): number {
    const parents = entries.filter(
      (e) => e.relatedDictionaries && e.relatedDictionaries[dictName].filter((f) => f.id === dictId).length > 0
    );
    return parents.length === 0 ? undefined : parents[0].id;
  }

  static findIdentifier(identifiers: CompanyIdentifierDto[], typeId: number, biSourceId: number): string {
    for (const identifier of identifiers) {
      if (
        (!typeId || DictionaryUtils.equalsDictAndId(identifier.type, typeId)) &&
        (!biSourceId || DictionaryUtils.equalsDictAndId(identifier.biSource, biSourceId))
      ) {
        return identifier.identifier;
      }
    }
    return undefined;
  }

  static findExternalId(identifiers: CompanyIdentifierDto[], biSourceId: number): string {
    return this.findIdentifier(identifiers, CompanyIdentifierType.EXTERNAL_ID, biSourceId);
  }

  static validity(validFrom: Date, validTo: Date) {
    const range = <LocalDateRange>{};
    range.dateFrom = validFrom;
    range.dateTo = validTo;
    return range;
  }

  static getNumberFromContractLink(link: ContractLinkDto): string {
    if (!link) {
      return '';
    }
    if (!link.linkType) {
      return '';
    }

    let number;
    switch (link.linkType.id) {
      case BusinessObjectType.CONTRACT:
        number = link.contract ? link.contract.number : '';
        break;
      case BusinessObjectType.POLICY:
        number = link.policyContract ? link.policyContract.number : '';
        break;
      default:
        number = 'undefined';
        break;
    }
    if (!number) {
      number = '';
    }
    return number;
  }

  static toCompanySimpleDto(c: CompanyDto): CompanySimpleDto {
    if (!c) {
      return undefined;
    }
    delete c.addresses;
    delete c.bankAccounts;
    delete c.branches;
    // iki 693
    // delete c.companyGroup;
    delete c.contactPersons;
    delete c.contacts;
    delete c.naceCodes;
    delete c.shareholders;
    delete c.warnings;
    delete c.physicalPerson;
    delete c.responsiblePersons;
    return <CompanySimpleDto>c;
  }

  static toPolicyInquiryVersionBaseDto(p: PolicyInquiryVersionSimpleDto): PolicyInquiryVersionBaseDto {
    return <PolicyInquiryVersionBaseDto>{
      id: p.id,
      productType: p.productType,
      divisionIntoFactorers: p.divisionIntoFactorers,
      falconLimitAllowed: p.falconLimitAllowed,
    };
  }

  static getThirdPartyCompany(tp: ThirdPartyBaseDto): {company: CompanySimpleDto; raw: boolean} {
    if (!tp) {
      return <any>{};
    } else if (tp.company) {
      return {company: tp.company, raw: false};
    } else {
      return {
        company: !tp.newCompanyRaw ? tp.firstCompanyRaw : tp.newCompanyRaw,
        raw: true,
      };
    }
  }

  static isFactoringPolicyType(typeId: number): boolean {
    return false;
  }

  static companyName(c: CompanyBaseDto | CompanyRawDto) {
    return c.registrationName ? c.registrationName : c.physicalPerson ? StringUtils.userName(c.physicalPerson) : '';
  }

  static prettyAddress(c: CompanyDto | CompanyBaseDto | CompanyRawDto | CompanyPortalDto) {
    if ((<CompanyDto>c).address) {
      return StringUtils.prettyAddress((<CompanyDto>c).address);
    } else {
      return StringUtils.prettyAddress((<CompanyPortalDto>c).registrationAddress);
    }
  }

  static initializeCompany(companyTypeId?: number, legalFormId?: number): CompanyDto {
    const company = <CompanyDto>{};
    company.localBranches = [];
    company.addresses = [];
    company.naceCodes = [];
    company.contacts = [];
    company.contactPersons = [];
    company.bankAccounts = [];
    company.branches = [];
    company.address = <AddressDto>{
      type: {id: AddressType.REGISTRATION},
      country: legalFormId === LegalForm.FP010 ? {id: Country.PL} : undefined,
    };
    company.shareholders = [];
    company.companyType = <DictionaryBaseDto>{id: companyTypeId || CompanyType.COMPANY};
    company.legalEvent = <LegalEventDto>{};
    company.companyGroupVersions = [];
    company.identifiers = [];
    company.physicalPerson = <PhysicalPersonDto>{};
    company.legalForm = legalFormId ? <DictionaryBaseDto>{id: legalFormId} : undefined;
    company.physicalPersonIndicator = legalFormId === LegalForm.FP010;
    return company;
  }

  static companyFromCompanyPortalDto(c: CompanyPortalDto): CompanyDto {
    const company = BusinessUtils.initializeCompany(CompanyType.COMPANY);
    company.companyType = <DictionaryBaseDto>{id: CompanyType.COMPANY};
    company.registrationName = c.registrationName;
    company.address = c.registrationAddress;
    company.address.type = <DictionaryBaseDto>{id: AddressType.REGISTRATION};
    if (c.correnspondenceAddress) {
      c.correnspondenceAddress.type = <DictionaryBaseDto>{
        id: AddressType.CORRESPONDENCE,
        name: 'Correspondence',
      };
      company.addresses.push(c.correnspondenceAddress);
    }
    if (c.executionAddress) {
      c.executionAddress.type = <DictionaryBaseDto>{
        id: AddressType.EXECUTION,
        name: 'Execution',
      };
      company.addresses.push(c.executionAddress);
    }
    if (c.contactPerson && c.contactPerson.lastName) {
      company.contactPersons.push(c.contactPerson);
    }
    company.language = c.language;
    company.legalForm = c.legalForm;
    company.nationalId = c.nationalId;
    company.physicalPerson = c.physicalPerson;
    company.physicalPersonIndicator = c.physicalPersonIndicator;
    if (c.pec) {
      company.contacts.push(<ContactDto>{type: {id: ContactType.PEC}, value: c.pec, main: false});
    }
    company.statNumber = c.statNumber;
    company.vatNumber = c.vatNumber;
    return company;
  }

  static companyRawToCompany(rawDto: CompanyRawDto): CompanyDto {
    const company = JSON.parse(JSON.stringify(rawDto));
    company.dataStatus = undefined;
    company.createdBy = undefined;
    company.email = undefined;
    company.phone = undefined;

    company.companyType = <DictionaryBaseDto>{id: CompanyType.COMPANY};
    company.legalEvent = <LegalEventDto>{};
    company.id = undefined;
    if (!company.address) {
      company.address = <AddressDto>{};
    }
    company.address.id = undefined;
    company.address.type = <DictionaryBaseDto>{id: AddressType.REGISTRATION};

    company.identifiers = [];
    return company;
  }

  public static isContractBooster(cv: ContractVersionSimpleDto): boolean {
    return (
      cv &&
      cv.contract &&
      cv.contract.type &&
      [ContractType.BOOSTER, ContractType.BANK_BOOSTER].includes(cv.contract.type.id)
    );
  }
}
