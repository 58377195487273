import {LimitDetailsComponent} from './limit-details.component';
import {LimitSearchComponent} from './limit-search.component';
import {LimitBusinessParamsDetailsComponent} from './business-params/limit-business-params-details.component';
import {LimitAccessGuard} from './guard/limit-access-guard';
import {LimitBusinessParamsGuard} from './guard/limit-business-params-guard';

export const LimitRoutes = [
  {
    path: 'limit-details/:id',
    component: LimitDetailsComponent,
    canActivate: [LimitAccessGuard],
    data: {module: 'limits'},
  },
  {
    path: 'limit-details',
    component: LimitDetailsComponent,
    canActivate: [LimitAccessGuard],
    data: {module: 'limits'},
  },
  {
    path: 'limit-search',
    component: LimitSearchComponent,
    canActivate: [LimitAccessGuard],
    data: {module: 'limits'},
  },
  {
    path: 'limit-business-params-details',
    component: LimitBusinessParamsDetailsComponent,
    canActivate: [LimitBusinessParamsGuard],
    data: {module: 'limits'},
  },
];
