import {HomeRoutes} from '../bonding/home/home.routes';
import {CompanyRoutes} from '../bonding/company/company.routes';
import {BondRoutes} from '../bonding/bond/bond.routes';
import {PortalAuthenticationGuard} from './portal.authentication-guard';
import {TaskRoutes} from '../bonding/task/task.routes';
import {ReportRoutes} from '../bonding/report/report.routes';
import {DocumentRoutes} from '../bonding/document/document.routes';
import {ContractRoutes} from '../bonding/bonding-contract/contract/contract.routes';
import {BrokerContractRoutes} from '../bonding/broker-contract/broker-contract.routes';
import {BusinessReportRoutes} from '../bonding/business-report/business-report.routes';
import {VersionRoutes} from '../bonding/version-info/version.routes';
import {PolicyContractRoutes} from '../bonding/policy/policy.routes';
import {LimitRoutes} from '../bonding/limit/limit.routes';
import {InvoiceRoutes} from '../bonding/invoice/invoice.routes';
import {InvoiceItemRoutes} from '../bonding/invoice-item/invoice-item.routes';
import {ClaimRoutes} from '../bonding/claim/claim.routes';
import {PortalComponent} from './portal.component';
import {BrokerRoutes} from './broker/broker.routes';
import {ClientBondRoutes} from './clientbond/clientbond.routes';
import {ClientTemplateRoutes} from './template/client-template.routes';
import {ClientPolicyRoutes} from './policy/client-policy.routes';
import {CommonContractRoutes} from './contract/common-contract.routes';
import {ClientInvoiceRoutes} from './invoice/client-invoice.routes';
import {InfoRoutes} from './info/info.routes';
import {GroupRoutes} from '../bonding/group/group.routes';
import {PolicyCessionRoutes} from '../bonding/policy-cession/policy-cession.routes';
import {InvoicePaymentRoutes} from '../bonding/invoice-payment/invoice-payment.routes';
import {PolicyLimitListRoutes} from '../bonding/policy-limit-list/policy-limit-list.routes';
import {BlockchainRoutes} from './blockchain';
import {ImportRoutes} from '../bonding/import/import.routes';
import {ReportExecutionRoutes} from '../bonding/report-execution/report-execution.routes';

export const PortalRoutes = [
  {
    path: 'bon',
    component: PortalComponent,
    canActivate: [PortalAuthenticationGuard],
    children: [
      ...HomeRoutes,
      ...ContractRoutes,
      ...PolicyContractRoutes,
      ...PolicyCessionRoutes,
      ...CompanyRoutes,
      ...GroupRoutes,
      ...BondRoutes,
      ...LimitRoutes,
      ...ImportRoutes,
      ...ReportRoutes,
      ...ReportExecutionRoutes,
      ...TaskRoutes,
      ...DocumentRoutes,
      ...BrokerContractRoutes,
      ...BusinessReportRoutes,
      ...VersionRoutes,
      ...InvoiceRoutes,
      ...InvoiceItemRoutes,
      ...InvoicePaymentRoutes,
      ...ClaimRoutes,
      ...ClientBondRoutes,
      ...ClientTemplateRoutes,
      ...ClientPolicyRoutes,
      ...CommonContractRoutes,
      ...BrokerRoutes,
      ...ClientInvoiceRoutes,
      ...InfoRoutes,
      ...PolicyLimitListRoutes,
      ...BlockchainRoutes,
    ],
  },
];
